import { useState } from 'react'
import logo from '../../assets/img/logo_ceerix.svg'
import { FiMenu, FiX } from 'react-icons/fi'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <nav className='bg-[#16389F] p-4 w-full'>
      <div className='flex justify-between items-center py-6 px-4 lg:px-16 relative '>
        {/* Logo */}
        <div className='text-white font-bold text-xl'>
          <a href=''>
            <img src={logo} alt='Logo' className='w-[150px]' />
          </a>
        </div>

        {/* Hamburger Menu Button */}
        <div className='lg:hidden text-white'>
          <button onClick={toggleMenu}>
            {isOpen ? <FiX size={28} /> : <FiMenu size={28} />}
          </button>
        </div>

        {/* Navigation Links */}
        <div
          className={`absolute top-16 left-0 w-full lg:w-auto bg-white lg:bg-[#16389F] pb-5 lg:p-0 lg:relative flex flex-col lg:flex-row  lg:items-center  transition-transform duration-300 ease-in-out ${
            isOpen ? 'flex' : 'hidden'
          } lg:flex lg:static lg:inset-0`}
          style={{ zIndex: 1000 }}
        >
          <ul className='flex flex-col gap-4 lg:flex-row  lg:space-y-0 text-white text-xl  font-medium'>
            <li  className='hover:bg-[#FF4E1F] p-4 '>
              <a href='/' className='lg:text-white text-[#16389F] hover:text-gray-300 '>
                Accueil
              </a>
            </li>
            <li  className='hover:bg-[#FF4E1F] p-4 '>
              <a href='#' className='lg:text-white text-[#16389F] hover:text-gray-300'>
                Caractéristiques
              </a>
            </li>
            <li  className='hover:bg-[#FF4E1F] p-4 '>
              <a href='#offres' className='lg:text-white text-[#16389F] hover:text-gray-300'>
                Nos Offres
              </a>
            </li>
            <li  className='hover:bg-[#FF4E1F] p-4 '>
              <a href='#' className='lg:text-white text-[#16389F] hover:text-gray-300'>
                Blog
              </a>
            </li>
            <li  className='hover:bg-[#FF4E1F] p-4 '>
              <a href='#contact' className='lg:text-white text-[#16389F] hover:text-gray-300'>
                Contact
              </a>
            </li>
          </ul>

          {/* Button */}
          <div className='mt-4 lg:mt-0'>
            <button className='bg-[#FF4E1F] px-4 py-2 rounded-xl ml-4 lg:ml-0 text-xl text-white'>
              <a href="/login">              Se connecter
</a>
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
