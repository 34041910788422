import { useState } from 'react';
import iconOrganisation from '../assets/img/icon-organisation.png';
import iconPreferences from '../assets/img/icon-preference.png';
import iconDelivery from '../assets/img/icon-delivery.png';
import iconPurchases from '../assets/img/icon-purchases.png';
import iconUsersRoles from '../assets/img/icon-users-roles.png';
import iconSales from '../assets/img/icon-sales.png';
import iconOnlinePayement from '../assets/img/icon-online-payement.png';
import iconIntegration from '../assets/img/icon-integration.png';
import iconTaxes from '../assets/img/icon-taxes.png';
import iconItems from '../assets/img/icon-items.png';
import iconClose from '../assets/img/icone-open-item.png'; // Add your open icon here
import iconOpen from '../assets/img/arrow-chevron-dropdown.png'; // Add your close icon here

const SettingsMenu = () => {
  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  return (
    <div className="p-4 w-[20%] gap-2 border-r h-screen">
      {/* Barre de recherche */}
      <div className="relative flex items-center border border-gray-300 rounded-md p-2 mb-4 w-full">
        <svg
          className="w-5 h-5 text-gray-500 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-4.35-4.35m1.44-3.69a7.5 7.5 0 111.8-1.8m-1.8 1.8A7.5 7.5 0 0121 21z"
          />
        </svg>
        <input
          type="text"
          className="w-full px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Search"
        />
      </div>

      {/* Contenu du menu des paramètres */}
      <ul className="space-y-5">
        {menuItems.map((item, index) => (
          <li key={index} className="cursor-pointer">
            <div className="flex items-center space-x-2" onClick={() => toggleMenu(index)}>
              <img src={item.icon} alt={`${item.name} Icon`} className="w-5 h-5" />
              <span>{item.name}</span>
              <img src={openMenu === index ? iconOpen : iconClose} alt="Toggle Icon" className="w-4 h-4 ml-auto" />
            </div>
            {openMenu === index && (
              <ul className="pl-8 mt-2 space-y-2">
                {item.subItems.map((subItem, subIndex) => (
                  <li key={subIndex} className="flex items-center space-x-2">
                    <a href={subItem.link} className="text-blue-500 hover:underline">
                      {subItem.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const menuItems = [
  {
    name: 'Organization',
    icon: iconOrganisation,
    subItems: [
      { name: 'Sub-item 1', link: '/organization/subitem1' },
      { name: 'Sub-item 2', link: '/organization/subitem2' },
    ],
  },
  {
    name: 'Taxes & Compliance',
    icon: iconTaxes,
    subItems: [
      { name: 'Sub-item 1', link: '/taxes/subitem1' },
      { name: 'Sub-item 2', link: '/taxes/subitem2' },
    ],
  },
  {
    name: 'Preferences',
    icon: iconPreferences,
    subItems: [
      { name: 'Sub-item 1', link: '/preferences/subitem1' },
      { name: 'Sub-item 2', link: '/preferences/subitem2' },
    ],
  },
  {
    name: 'Items',
    icon: iconItems,
    subItems: [
      { name: 'Sub-item 1', link: '/items/subitem1' },
      { name: 'Sub-item 2', link: '/items/subitem2' },
    ],
  },
  {
    name: 'Sales',
    icon: iconSales,
    subItems: [
      { name: 'Sub-item 1', link: '/sales/subitem1' },
      { name: 'Sub-item 2', link: '/sales/subitem2' },
    ],
  },
  {
    name: 'Purchases',
    icon: iconPurchases,
    subItems: [
      { name: 'Sub-item 1', link: '/purchases/subitem1' },
      { name: 'Sub-item 2', link: '/purchases/subitem2' },
    ],
  },
  {
    name: 'Users & Roles',
    icon: iconUsersRoles,
    subItems: [
      { name: 'Users', link: '/settings/users-roles/users' },
      { name: 'Roles', link: '/settings/users-roles/roles' },
    ],
  },
  {
    name: 'Online Payments',
    icon: iconOnlinePayement,
    subItems: [
      { name: 'Sub-item 1', link: '/online-payments/subitem1' },
      { name: 'Sub-item 2', link: '/online-payments/subitem2' },
    ],
  },
  {
    name: 'Integrations',
    icon: iconIntegration,
    subItems: [
      { name: 'Sub-item 1', link: '/integrations/subitem1' },
      { name: 'Sub-item 2', link: '/integrations/subitem2' },
    ],
  },
  {
    name: 'Delivery Method',
    icon: iconDelivery,
    subItems: [
      { name: 'Sub-item 1', link: '/delivery-method/subitem1' },
      { name: 'Sub-item 2', link: '/delivery-method/subitem2' },
    ],
  },
];

export default SettingsMenu;
