import  { useState } from 'react';
import PropTypes from 'prop-types';
import iconModify from '../assets/img/icon-modify-white (2).png';

const CustomUserDetail = ({ user, onClose, onEdit }) => {
  const [activeTab, setActiveTab] = useState('MoreDetail');

  return (
    <>
      <div className='flex gap-10 w-full justify-between items-start p-4 border-b'>
        <div className='flex items-center gap-4'>
          <div className='flex items-center'>
            <img
              className='w-24 h-24 rounded-full ml-4'
              src={user.image}
              alt={user.name}
            />
          </div>
          <div className='ml-4 flex-grow gap-20'>
            <div className='text-lg font-semibold'>
              {user.name} -{' '}
              <span
                className={
                  user.status === 'inactive' ? 'text-red-500' : 'text-blue-500'
                }
              >
                {user.status}
              </span>
            </div>
            <div className='text-gray-600'>{user.email}</div>
            <div className='text-gray-600 mr-4'>{user.role}</div>
          </div>
        </div>
        <div className='flex items-center justify-center gap-6'>
          <button
            onClick={() => onEdit(user)}
            className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex gap-2'
          >
            <img src={iconModify} className='h-5 w-5' alt='Modify' />
            <span>Edit</span>
          </button>
          <button
            id='details-btn'
            className='p-2 rounded border focus:outline-none hover:bg-gray-400 bg-gradient-to-r from-[#FFFFFF14] to-[#6666660C] h-10 w-10'
          >
            <svg
              className='w-6 h-6 text-gray-600'
              fill='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12 8c-1.1 0-1.99-.89-1.99-2S10.9 4 12 4s2 .89 2 2-.9 2-2 2zm0 4c-1.1 0-1.99-.89-1.99-2S10.9 8 12 8s2 .89 2 2-.9 2-2 2zm0 4c-1.1 0-1.99-.89-1.99-2S10.9 12 12 12s2 .89 2 2-.9 2-2 2z' />
            </svg>
          </button>
          <button
            onClick={onClose}
            className='text-4xl text-gray-500 hover:text-gray-700'
          >
            &times;
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-4 border-b">
        <button
          className={`mr-6 pb-2 ${activeTab === 'MoreDetail' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'}`}
          onClick={() => setActiveTab('MoreDetail')}
        >
          More Detail
        </button>
        <button
          className={`pb-2 ${activeTab === 'RecentActivity' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'}`}
          onClick={() => setActiveTab('RecentActivity')}
        >
          Recent Activity
        </button>
      </div>

      {/* Tab Content */}
      <div className="mt-4 p-4">
        {activeTab === 'MoreDetail' && (
          <div>
            <p><strong>Phone:</strong> {user.phone || 'N/A'}</p>
            <p><strong>Address:</strong> {user.address || 'N/A'}</p>
            <p><strong>Company:</strong> {user.company || 'N/A'}</p>
            {/* Ajouter d'autres détails si nécessaire */}
          </div>
        )}

        {activeTab === 'RecentActivity' && (
          <div>
            <p><strong>Last Login:</strong> {user.lastLogin || 'N/A'}</p>
            <p><strong>Recent Actions:</strong> {user.recentActions && user.recentActions.length > 0 
              ? user.recentActions.join(', ') 
              : 'No recent actions available'}
            </p>
            {/* Ajouter d'autres activités récentes si nécessaire */}
          </div>
        )}
      </div>
    </>
  );
};

CustomUserDetail.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    phone: PropTypes.string,
    address: PropTypes.string,
    company: PropTypes.string,
    lastLogin: PropTypes.string,
    recentActions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default CustomUserDetail;
