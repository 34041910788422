import iconDropdownBlack from '../assets/img/Vector.png'
import logoApple from '../assets/img/logo-apple.png'

const GreetSection = () => {
  return (
    <div className='greet-section w-1/3'>
      {/* <div className=''> */}
      <img src={logoApple} className='w-9 h-9' alt='' />
      <div className='flex gap-3 items-center'>
        <div className='flex flex-col justify-center'>
          <h4>
            Hello, <span className='username'>User name</span>{' '}
          </h4>
          <span className='status text-[#929FB1]'>Office On</span>
        </div>
        <img src={iconDropdownBlack} className='w-5 hover:cursor-pointer' alt='' />
      </div>

      {/* </div> */}
    </div>
  )
}

export default GreetSection
