// export default SignupForm;
import { useState } from "react";
// import '../dist/output.css';
import "../App.css";
import PropTypes from "prop-types";
import PhoneNumberForm from "../components/PhoneNumberForm";

// const countryCodes = [
//   { code: "+1", country: "USA" },
//   { code: "+44", country: "UK" },
//   { code: "+33", country: "France" },
//   { code: "+229", country: "Benin" },
//   // Ajoutez d'autres indicatifs de pays ici
// ];

const SignupForm = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [countryCode, setCountryCode] = useState(countryCodes[0].code);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      firstName,
      lastName,
      email,
      // phone: `${countryCode}${phone}`,
      password,
      confirmPassword,
      termsAccepted,
    });
  };

  return (
    <div className="flex justify-center pt-16">
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-semibold mb-4">Sign up</h2>
        <div className="flex space-x-4 mb-4">
          <div className="form-group">
            <label htmlFor="firstName">First name</label>
            <input
              id="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Type here"
              className="input-field"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last name</label>
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Type here"
              className="input-field"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type here"
            className="input-field mb-4"
          />
        </div>
        {/* <div className='mb-4'>
    <label className='block text-sm font-medium text-gray-700'>Phone</label>
    <div className='flex'>
      <select
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        className='border border-gray-300 bg-white border-r-0 px-2 rounded-l'
      >
        {countryCodes.map((code) => (
          <option key={code.code} value={code.code}>
            {code.code}
          </option>
        ))}
      </select>
      <input
        type='number'
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className='border border-gray-300 rounded-r'
        placeholder='Phone number'
      />
    </div>
  </div> */}
        <PhoneNumberForm />
        <div className="form-group">
          <label htmlFor="password">Create password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="At least 6 characters with letter"
            className="input-field mb-4"
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Repeat password</label>
          <input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Type here"
            className="input-field mb-4"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded w-full"
        >
          Sign up
        </button>
        <div className="terms-section flex items-center mt-4">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            id="terms"
            className="mr-2"
          />
          <label htmlFor="terms">I agree with Terms and Conditions</label>
        </div>
        <div className="mt-4">
          <span>Already have an account?</span>
          <a href="/" className="text-blue-500 ml-1">
            Sign in
          </a>
        </div>
      </form>
    </div>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
