import PropTypes from 'prop-types';
import iconModify from '../assets/img/icon-modified.png';

const UserDetail = ({ user, onEdit }) => {
  if (!user) {
    console.error('UserDetail received undefined user');
    return null;
  }

  return (
    <div className="flex w-full justify-between items-center p-4 pr-32 border-b">
      <div className="flex items-center gap-4 w-1/2">
        <div className="flex items-center">
          <input type="checkbox" className="w-4 h-4 flex-shrink-0" />
          <img
            className="w-24 h-24 rounded-full ml-4"
            src={user.image}
            alt={user.name}
          />
        </div>
        <div className="ml-4 flex-grow">
          <div className="text-lg font-semibold">
            {user.name} -{' '}
            <span className={user.status === 'inactive' ? 'text-red-500' : 'text-blue-500'}>
              {user.status}
            </span>
          </div>
          <div className="text-gray-600">{user.email}</div>
        </div>
      </div>
      <div className="flex items-center justify-between w-1/2">
        <div className="text-gray-600 mr-4">{user.role}</div>
        <button className="text-gray-500 hover:text-gray-800" onClick={() => onEdit(user)}>
          <img src={iconModify} className="h-6 w-6" alt="Modify" />
        </button>
      </div>
    </div>
  );
};

UserDetail.propTypes = {
  user: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default UserDetail;
