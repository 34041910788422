// InvoiceComponent.jsx

import { useState } from 'react';
import PropTypes from 'prop-types';

function InvoiceComponent({ ticketId, clientId }) {
  const [type, setType] = useState('Simple');

  return (
    <div className="flex justify-between p-4 bg-black text-white">
      <div>
        <div>Ticket : #{ticketId}</div>
        <div>Client : #{clientId}</div>
        <div>Type : {type}</div>
      </div>

      <div className="flex flex-col gap-4">
        <span className="font-bold">Invoice</span>
        <div className="flex justify-between gap-2">
          <button 
            className={`button ${type === 'Simple' ? 'bg-[#03A01C]' : 'bg-gray-500'}`} 
            onClick={() => setType('Simple')}
          >
            Simple
          </button>
          <button 
            className={`button ${type === 'Stand' ? 'bg-[#03A01C]' : 'bg-gray-500'}`} 
            onClick={() => setType('Stand')}
          >
            Stand
          </button>
        </div>
      </div>
    </div>
  );
}

InvoiceComponent.propTypes = {
  ticketId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};

export default InvoiceComponent;
