import PropTypes from 'prop-types'

const RoleDetail = ({ user }) => {
  if (!user) {
    console.error('RoleDetail received undefined user')
    return null
  }

  return (
    <div className='flex w-full justify-between items-center p-4 pr-32 border-b'>
      <div className='flex items-center gap-4 w-1/2'>
        <div className='ml-4 flex-grow'>
          <div className='text-sm text-blue-400'>{user.role} </div>
        </div>
      </div>
      <div className='flex items-center justify-between w-1/2'>
        <div className='text-gray-600 mr-4'>{user.description}</div>

      </div>
    </div>
  )
}

RoleDetail.propTypes = {
  user: PropTypes.shape({
    description: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired
}

export default RoleDetail
