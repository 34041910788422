const ProductTags = () => {
  return (
    <div className='mb-4 p-4 rounded-md'>
      <label className='block text-gray-700 mb-4'>Product Tags</label>
      <div className=" flex flex-col gap-3 items-center bg-white py-4">
        <div className='flex mb-2 w-[90%]'>
          <input type='text' className='form-input flex-grow border border-[#B0BECB] rounded-l-md' />
          <button className='bg-blue-500 text-white px-4 py-2 rounded-r-md'>
            Addon
          </button>
        </div>
        <span>Separate Tags with commas</span>
      </div>
    </div>
  )
}

export default ProductTags
