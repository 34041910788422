import PropTypes from 'prop-types'

const Card = ({ icon, title, description }) => {
  return (
    <div className='border w-full lg:w-[30%] border-white rounded-[29px] bg-[#1235A0] h-auto lg:h-[300px] px-6 pt-6 shadow-lg flex flex-col justify-center items-center lg:items-start text-center lg:text-start'>
      {/* Icône */}
      <div className='flex w-full items-center'>
        <div className='flex items-center justify-center lg:justify-start mb-4'>
          <img src={icon} alt='' className='w-12 h-12 lg:w-auto lg:h-auto' />
        </div>

        {/* Titre */}
        <h3 className='text-[20px] lg:text-[23px] w-full font-semibold mb-2 font-codec'>
          {title}
        </h3>
      </div>

      {/* Paragraphe */}
      <p className='text-[#E8E8E8] w-full mt-2 lg:mt-4 text-[15px] lg:text-[17px]'>
        {description}
      </p>
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default Card
