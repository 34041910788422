import PropTypes from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ItemsBar = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { name: 'All Items', icon: 'fas fa-th-large' },
    { name: 'Family', icon: 'fas fa-users' },
    { name: 'Category', icon: 'fas fa-sitemap' },
    { name: 'Order', icon: 'fas fa-receipt' },
    { name: 'Table', icon: 'fas fa-table' }
  ];

  return (
    <header className="header">
      {tabs.map(tab => (
        <button 
          key={tab.name} 
          className={`${activeTab === tab.name ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.name)}
        >
          <i className={tab.icon}></i>&nbsp; 
          {tab.name}
        </button>
      ))}
    </header>
  );
};

ItemsBar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default ItemsBar;
