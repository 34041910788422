
import PropTypes from 'prop-types';
import iconSettings from '../assets/img/icon-settings.png';
import iconBack from '../assets/img/icon-back.png';

const SettingsHeader = ({ onSettingsClick, showSettingsMenu }) => {
  return (
    <div className='flex max-w-full items-center space-x-4 gap-28 pl-20 pr-10'>
      {/* Settings Icon */}
      <div className='flex items-center space-x-2' onClick={onSettingsClick}>
        <img
          src={showSettingsMenu ? iconBack : iconSettings}
          alt='Settings Icon'
          className='w-6 h-6 cursor-pointer'
        />
        <span className='text-gray-600'>{showSettingsMenu ? 'Back to all settings' : 'Settings'}</span>
      </div>

      {/* Search Input */}
      <div className='flex-1'>
        <div className='relative flex items-center border border-gray-300 rounded-md p-2 w-full'>
          <svg
            className='w-5 h-5 text-gray-500'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M21 21l-4.35-4.35m1.44-3.69a7.5 7.5 0 111.8-1.8m-1.8 1.8A7.5 7.5 0 0121 21z'
            />
          </svg>
          <input
            type='text'
            className='w-full px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            placeholder='Search'
          />
        </div>
      </div>

      {/* Clear Button */}
      <button className='flex items-center justify-center hover:bg-gray-400 bg-gradient-to-r from-[#FFFFFF14] to-[#6666660C] h-10 w-10 text-pink-500 rounded border'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-6 w-6'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>
    </div>
  );
};

SettingsHeader.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
  showSettingsMenu: PropTypes.bool.isRequired,
};

export default SettingsHeader;
