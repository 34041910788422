import PropTypes from 'prop-types'

const ClientReview = ({ image, reviewText, clientInfo }) => {
  return (
    <div className='w-full lg:w-[35%] bg-[#FF4E1F] rounded-lg shadow-lg p-6 h-[226px] flex  items-center gap-6'>
      {/* Client Image */}
      <img
        className='w-24 h-24 rounded-full mb-4 object-cover shadow-md'
        src={image}
        alt={`${clientInfo} avatar`}
      />

      <div className='flex flex-col gap-[13px]'>
        {/* Review Text */}
        <p className='text-white text-xl  mb-4'>{reviewText}</p>
        {/* Client Name */}
        <h3 className='text-sm font-normal text-start text-white italic'>
          {clientInfo}
        </h3>
      </div>
    </div>
  )
}

ClientReview.propTypes = {
  image: PropTypes.string.isRequired, // URL of the image
  reviewText: PropTypes.string.isRequired, // The review text (avis)
  clientInfo: PropTypes.string.isRequired // Client's name (nom)
}

export default ClientReview
