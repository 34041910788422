// src/api/authAPI.js
import axiosInstance from "./axiosInstance.js";

export const login = async (credentials) => {
  const response = await axiosInstance.post("/login", credentials);
  console.log(response);
  return response.data;
};

export const register = async (credentials) => {
  const response = await axiosInstance.post("/register", credentials);
  return response.data;
};
