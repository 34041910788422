const ProductDetails = () => {
    return (
      <div className="product-container">
        <div className="product-title">Product Details</div>
        <div className="product-details-wrapper">
          <div className="product-detail-item">
            <div className="product-detail-text">
              <div className="product-detail-icon bg-[#246BF6]" />
              <span className="text-[#606E80]">All Items</span>
            </div>
            <div className="product-detail-value text-[#606E80]">
              255
            </div>
          </div>
          <div className="product-detail-item">
            <div className="product-detail-text">
              <div className="product-detail-icon bg-[#62AAFF]" />
              <span className="text-[#606E80]">All Items Groups</span>
            </div>
            <div className="product-detail-value text-[#E02424]">
              25
            </div>
          </div>
          <div className="product-detail-item">
            <div className="product-detail-text">
              <div className="product-detail-icon bg-[#ff4500]" />
              <span className="text-[#E02424]">Low Stock Items</span>
            </div>
            <div className="product-detail-value text-[#E02424]">
              22
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ProductDetails;