const TopSellingItemsTable = () => {
  const data = [
    {
      channel: 'Direct',
      orders: 25,
      confirmed: 25,
      package: 25,
      shipped: 25,
      invoiced: 25
    },
    {
      channel: 'Direct',
      orders: 25,
      confirmed: 25,
      package: 25,
      shipped: 25,
      invoiced: 25
    }
  ]

  return (
    <div className='top-selling-container'>
      <div className='top-selling-header'>
        <div className='top-selling-title'>Top Selling Items</div>
        <select className='top-selling-period'  name="" id="">
            <option value="">This Month</option>
        </select>
      </div>
      <div className='top-selling-items-container'>
        <table>
          <thead>
            <tr>
              <th>Channel</th>
              <th>Orders</th>
              <th>Confirmed</th>
              <th>Package</th>
              <th>Shipped</th>
              <th>Invoiced</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.channel}</td>
                <td>{item.orders}</td>
                <td>{item.confirmed}</td>
                <td>{item.package}</td>
                <td>{item.shipped}</td>
                <td>{item.invoiced}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TopSellingItemsTable
