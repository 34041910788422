import RoleDetail from './RoleDetail';

const RoleList = () => {
  const users = [
    {
      role: 'Staff',
      description: 'Access to all modules except reports, settings and accountant.',
    },
    {
      
      role: 'Admin',
      description: 'Unrestricted access to all modules.',
    },
  ];

  return (
    <div className="w-full max-w-full ">
      <div className="flex w-full p-4 bg-gray-100 border-b">
        <div className="flex items-center gap-4 w-1/2">
          {/* <input type="checkbox" className="w-4 h-4 flex-shrink-0" /> */}
          <div className="ml-4 text-lg font-semibold flex-grow uppercase">Roles Names</div>
        </div>
        <div className="flex items-center justify-between w-1/2">
          <div className="text-lg font-semibold uppercase">Description</div>
          <div className="w-12"></div>
        </div>
      </div>
      {users.map((user, index) => (
        <div key={index} className="cursor-pointer">
          <RoleDetail user={user} />
        </div>
      ))}
    </div>
  );
};


export default RoleList;
