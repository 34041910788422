
const Filters = () => {
  return (
    <div className="flex items-center justify-start space-x-4  p-4 bg-transparent">
      <select className="border border-gray-300 rounded-md px-4 py-2">
        <option value="all">All</option>
        {/* Autres options ici */}
      </select>
      <select className="border border-gray-300 rounded-md px-4 py-2">
        <option value="category">Select a category</option>
        {/* Autres options ici */}
      </select>
      <select className="border border-gray-300 rounded-md px-4 py-2">
        <option value="product-type">Sort by product type</option>
        {/* Autres options ici */}
      </select>
      <select className="border border-gray-300 rounded-md px-4 py-2">
        <option value="stock-status">Sort by stock status</option>
        {/* Autres options ici */}
      </select>
      <select className="border border-gray-300 rounded-md px-4 py-2">
        <option value="group-action">Group action</option>
        {/* Autres options ici */}
      </select>
    </div>
  );
};

export default Filters;
