import ItemImage from './ItemImage';
import ProductCategory from './ProductCategory';
import ProductTags from './ProductTags';

const ProductFormRight = () => {
  return (
    <div className="max-w-full  h-screen">
      <ItemImage />
      <ProductCategory />
      <ProductTags />
    </div>
  );
};

export default ProductFormRight;