import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'

import { useEffect } from 'react';

function AllStores(){
    useEffect(()=>{
        document.title ='All Sores'
    }, [])
    return (
    <div className='flex bg-[#E8E8E8] w-full'>
        <NavBar></NavBar>
        <div className='w-full'>
            <TopBar></TopBar>
            
        </div>
    </div>
)
}


export default AllStores;