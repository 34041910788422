import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../dist/output.css";
import googleIcon from "../assets/img/icon.png";
// import PropTypes from "prop-types";

import { useAuth } from "../hooks/useAuth.js";
import { login as loginAPI } from "../api/auth.js";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // try {
    const userData = await loginAPI({ email, password });
    login(userData);
    navigate("/dashboard");
    // Navigate("/dashboard");
    // } catch (err) {
    //   // setError("Invalid username or password");
    // }
  };

  return (
    <div className="flex justify-center pt-16">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-semibold">Sign in</h2>
        <div className="form-group">
          <label htmlFor="username">Email</label>
          <input
            id="username"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type here"
            className="input-field"
          />
        </div>
        <div className="password-section">
          {/* <div className='form-group'> */}
          <div className="flex justify-between gap-52">
            {" "}
            <label htmlFor="password">Password</label>
            <a href="/forgot" className="forgot-link">
              Forgot?
            </a>
          </div>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Type here"
            className="input-field"
          />
          {/* </div> */}
        </div>
        <div className="remember-me">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            id="rememberMe"
          />
          <label htmlFor="rememberMe">Keep me signed in</label>
        </div>
        <button type="submit" className="sign-in-button">
          Sign in
        </button>
        <div className="or-divider">OR</div>
        <button type="button" className="google-sign-in">
          <img src={googleIcon} alt="Google icon" />
          <span>Continue with Google</span>
        </button>
        <div className="register-section">
          <span>Don’t have an account?</span>
          <a href="/signup" className="register-link">
            Register now
          </a>
        </div>
      </form>
    </div>
  );
};

// LoginForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default LoginForm;
