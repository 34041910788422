const SalesSection = () => {
    return (
      <div className=" sales-section-container">
        <div className=" sales-section-header">
          <div className=" sales-section-title">Sales</div>
          <div className="sales-section-period">This Month</div>
        </div>
        <div className="sales-section-total-cost">
          <p>Total Cost</p>
          <div id="total-cost-value"><span>10 000 000 000</span> F</div>
        </div>
      </div>
    )
  }
  
  export default SalesSection;