import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import infSign from '../assets/img/inner.png';
import supSign from '../assets/img/inner(2).png';
import novaLogo from '../assets/img/nova 1.png';
import novaLogoLetter from '../assets/img/nova bleu.png';
import iconHome from '../assets/img/home-main.png';
import iconCash from '../assets/img/cashier-stroke-rounded.png';
import iconSale from '../assets/img/shopping-cart.png';
import iconPushcase from '../assets/img/bag.png';
import iconInventory from '../assets/img/box-product.png';
import iconMessage from '../assets/img/chat-message.png';
import iconDelivery from '../assets/img/truck-delivery.png';
import iconStore from '../assets/img/store-add-01-stroke-rounded.png';
import iconReports from '../assets/img/analytics-02 1.png';
import iconFinance from '../assets/img/chart-donut.png';
import iconIntegration from '../assets/img/connect-integrate.png';
import iconFolder from '../assets/img/folder.png';
import iconBank from '../assets/img/Bank.png';

const menuItems = [
  { path: '/home', icon: iconHome, title: 'Home' },
  { path: '/cash-register', icon: iconCash, title: 'Cash Register' },
  {
    path: '/sales', icon: iconSale, title: 'Sales', submenu: [
      { path: '/sales/overview', title: 'Overview' },
      { path: '/sales/reports', title: 'Reports' }
    ]
  },
  {
    path: '/purchase', icon: iconPushcase, title: 'Purchase', submenu: [
      { path: '/purchase/orders', title: 'Orders' },
      { path: '/purchase/suppliers', title: 'Suppliers' }
    ]
  },
  {
    path: '/inventory', icon: iconInventory, title: 'Inventory', submenu: [
      { path: '/inventory/items', title: 'Items' },      
      { path: '/inventory/categories', title: 'Category' },
      { path: '/inventory/items-box', title: 'Items Set' },
      { path: '/inventory/price-list', title: 'Price List' },

    ]
  },
  { path: '/messages', icon: iconMessage, title: 'Messages' },
  { path: '/delivery', icon: iconDelivery, title: 'Delivery' },
  { path: '/online-store', icon: iconStore, title: 'Online Store' },
  { path: '/reports', icon: iconReports, title: 'Reports' },
  {
    path: '/finances', icon: iconFinance, title: 'Finances', submenu: [
      { path: '/finances/accounts', title: 'Accounts' },
      { path: '/finances/budgets', title: 'Budgets' }
    ]
  },
  { path: '/integrations', icon: iconIntegration, title: 'Integrations' },
  { path: '/documents', icon: iconFolder, title: 'Documents' },
  {
    path: '/all-stores', icon: iconBank, title: 'All Stores', submenu: [
      { path: '/all-stores/store1', title: 'Store 1' },
      { path: '/all-stores/store2', title: 'Store 2' }
    ]
  }
];

const NavBar = () => {
  const location = useLocation();
  const [activeSubmenu, setActiveSubmenu] = useState('');

  const handleMenuClick = (event, menu) => {
    event.preventDefault();
    setActiveSubmenu(activeSubmenu === menu ? '' : menu);
  };

  const isSubmenuVisible = (menu) => activeSubmenu === menu;

  const isActive = (path) => {
    if (location.pathname === path) return true;

    const menuItem = menuItems.find(item => item.path === path);
    if (menuItem?.submenu) {
      return menuItem.submenu.some(subItem => location.pathname === subItem.path);
    }
    
    return false;
  };

  const handleClickInf = () => {
    const sideSpans = document.querySelectorAll('.side-nav-item-title');
    const blueLogo = document.querySelector('.blue-nova-logo');
    const novaLogo = document.querySelector('.nova-logo');
    const infSign = document.querySelector('.base-navbar .inf-sign');
    const supSign = document.querySelector('.base-navbar .sup-sign');
    const baseActions = supSign.parentElement;
    const sideNavItems = document.querySelectorAll('.side-nav-item');

    sideSpans.forEach(sideSpan => {
      const sideImage = sideSpan.parentElement;

      sideSpan.classList.add('hidden');
      sideImage.classList.add('bg-gradient-to-r', 'from-custom-start', 'to-custom-end');
    });

    blueLogo.classList.remove('hidden');
    novaLogo.classList.add('hidden');
    infSign.classList.add('hidden');
    supSign.classList.remove('hidden');
    baseActions.style.paddingLeft = '2px';
    baseActions.style.paddingRight = '2px';
    sideNavItems.forEach(sideNavItem => {
      sideNavItem.classList.add('justify-center');
    });
  };

  const handleClickSup = () => {
    const sideSpans = document.querySelectorAll('.side-nav-item-title');
    const blueLogo = document.querySelector('.blue-nova-logo');
    const novaLogo = document.querySelector('.nova-logo');
    const infSign = document.querySelector('.base-navbar .inf-sign');
    const supSign = document.querySelector('.base-navbar .sup-sign');
    const sideNavItems = document.querySelectorAll('.side-nav-item');

    sideSpans.forEach(sideSpan => {
      const sideImage = sideSpan.parentElement;

      sideSpan.classList.remove('hidden');
      sideImage.classList.remove('bg-gradient-to-r', 'from-custom-start', 'to-custom-end');
    });

    blueLogo.classList.add('hidden');
    novaLogo.classList.remove('hidden');
    infSign.classList.remove('hidden');
    supSign.classList.add('hidden');
    sideNavItems.forEach(sideNavItem => {
      sideNavItem.classList.remove('justify-center');
    });
  };

  return (
    <div className='side-nav w-[25%]'>
      <div className='side-nav-sections'>
        <div className='side-nav-header'>
          <h1 className='side-nav-logo'>
            <img src={novaLogo} alt='Nova Logo' className='nova-logo' />
            <img
              src={novaLogoLetter}
              alt='Nova Logo Letter'
              className='w-12 h-11 hidden blue-nova-logo'
            />
          </h1>
        </div>
        <nav className='side-nav-menu'>
          <ul>
            {menuItems.map((menuItem) => (
              <li key={menuItem.path}>
                <NavLink
                  to={menuItem.path}
                  className={`side-nav-item ${
                    isActive(menuItem.path) || isSubmenuVisible(menuItem.path) ? 'active' : ''
                  }`}
                  onClick={(event) => menuItem.submenu && handleMenuClick(event, menuItem.path)}
                >
                  <img
                    src={menuItem.icon}
                    alt={`${menuItem.title} Icon`}
                    className={`icon ${
                      isSubmenuVisible(menuItem.path) || location.pathname === menuItem.path ? 'text-[#FFC900]' : ''
                    }`}
                  />
                  <span className='side-nav-item-title'>{menuItem.title}</span>
                </NavLink>
                {menuItem.submenu && isSubmenuVisible(menuItem.path) && (
                  <ul className='sub-menu'>
                    {menuItem.submenu.map((subItem) => (
                      <li key={subItem.path} className={location.pathname === subItem.path ? 'active' : ''}>
                        <NavLink
                          to={subItem.path}
                        >
                          {subItem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className='bg-[#716D6D30] text-white flex justify-center items-center w-full text-center p-5 hover:cursor-pointer base-navbar'>
        <img
          src={infSign}
          alt='Inf Sign'
          className='inf-sign'
          onClick={handleClickInf}
        />
        <img
          src={supSign}
          alt='Sup Sign'
          className='sup-sign hidden'
          onClick={handleClickSup}
        />
      </div>
    </div>
  );
};

export default NavBar;
