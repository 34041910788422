const ItemImage = () => {
  return (
    <div className='mb-4 p-4 rounded-md flex flex-col gap-2'>
      <label className='block text-gray-700 mb-2'>Item Image</label>
      <div className='flex flex-col items-center bg-white gap-2 p-4'>
        <div className='border-dashed border-2 border-[#E1E5EA] w-[200px] h-[140px] rounded-md p-4 text-center'></div>{' '}
        <div className='text-blue-500'>Browse Image</div>
      </div>
      <label className='block text-gray-700 mb-2'>Item Image</label>
      <div className='border-solid border-2 bg-white  rounded-md p-3 text-center'>
        <button className=' text-blue-500'>
          Add images to product gallery
        </button>
      </div>{' '}
    </div>
  )
}

export default ItemImage
