import PropTypes from 'prop-types'

const ItemCard = ({ name, category, price, stock }) => (
  <div className='item-card bg-white'>
    <div className='flex gap-2'>
      {' '}
      <div className='item-image'></div>
      <div>
        <h4 className='font-medium'>{name}</h4>
        <p className='text-sm text-gray-500'>{category}</p>
      </div>
    </div>
    <div className='flex justify-between'>
      <p className='font-bold'>{price} FCFA</p>
      <p className='text-sm text-[#03A01C] bg-[#CEFDDE] py-1 px-2 rounded-3xl'>
        Stk {stock}
      </p>
    </div>
  </div>
)

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  stock: PropTypes.string.isRequired
}

export default ItemCard
