import '@fortawesome/fontawesome-free/css/all.min.css';

const ButtonGroup = () => {
  return (
    <div className="flex space-x-2">
      <button className="btn-default">
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
          <path d="M12 2L2 12L12 22L22 12L12 2Z" />
        </svg>
        Button
      </button>
      <button className="btn-selected">
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
          <path d="M12 2L2 12L12 22L22 12L12 2Z" />
        </svg>
        Selected
      </button>
    </div>
  );
};

export default ButtonGroup;
