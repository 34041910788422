// import React from 'react';
import iconSearch from '../assets/img/icon-search.png'
import iconAdd from '../assets/img/icon-add.png'
import iconButton from '../assets/img/Icon-button.png'
// import iconDraw from '../assets/img/icon-draw.png';
import iconNotifications from '../assets/img/icon-notifications.png'
import iconOpen from '../assets/img/icon-open.png'
import iconSettings from '../assets/img/icon-settings.png'
import iconDropdownBlue from '../assets/img/arrow-chevron-dropdown.png'
import iconDropdownBlack from '../assets/img/Vector.png'
import avatar from '../assets/img/avatar.png'
import iconScan from '../assets/img/Icon-scan.png'

function TopBar () {
  return (
    <div className='bg-[#F7F7FE] flex items-center px-4 gap-[340px] max-w-full justify-between'>
      <div className='flex justify-center items-center'>
        <img src={iconButton} alt='' />
        <div className='flex-grow'>
          <div className='relative bg-[#DDDDDD33]'>
            <input
              type='text'
              placeholder='     | Search in Itemes ( / )'
              className='w-full px-4 py-2 rounded-lg border border-gray-300 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
            <span className='flex'>
              <img
                src={iconSearch}
                alt='Search Icon'
                className='absolute left-3 top-1/2 transform -translate-y-1/2'
              />
              <img
                src={iconDropdownBlue}
                alt=''
                className='absolute left-10 top-1/2 transform -translate-y-1/2 hover:cursor-pointer'
              />
            </span>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='flex items-center gap-7 ml-4'>
          <img
            src={iconAdd}
            alt='Add Icon'
            className='w-6 h-6 mr-2 cursor-pointer'
          />{' '}
          <div className='flex items-center border-x border-[#B2B0BF] px-4'>
            {/* <img src={iconDraw} alt="Draw Icon" className="w-6 h-6 mr-2 cursor-pointer" /> */}
            <img
              src={iconOpen}
              alt='Settings Icon'
              className='w-6 h-6 mr-4 cursor-pointer'
            />
            <img
              src={iconNotifications}
              alt='Settings Icon'
              className='w-6 h-6 mr-4 cursor-pointer'
            />
            <a href='/settings'>
              {' '}
              <img
                src={iconSettings}
                alt='Settings Icon'
                className='w-6 h-6  cursor-pointer'
              />
            </a>
          </div>
          <div className='flex items-center gap-1 justify-between'>
            {' '}
            <span className='font-normal text-[#404B5A]'>User Name</span>
            <img
              src={iconDropdownBlack}
              alt=''
              className='hover:cursor-pointer w-3 h-1'
            />
          </div>
          <img src={avatar} alt='' />
          <img src={iconScan} alt='' />
        </div>
      </div>
    </div>
  )
}

export default TopBar
