import PropTypes from 'prop-types';

const OrderCard = ({ orderNumber, status, itemCount, time }) => (
  <div className={`order-card ${status.toLowerCase()}`}>
    <div className='flex justify-between items-center mb-2 px-4 py-4 border-b border-gray-300'>
      <h4>Order: #{orderNumber}</h4>
      <div className={`status ${status.toLowerCase()}`}>{status}</div>
    </div>
        <p className='text-gray-500 pr-4 text-end text-sm'>{time}</p>

    <div className='flex justify-between items-center p-4 '>
      <p className='items'>{itemCount} items</p>
      <div className='flex justify-between items-center mt-2'>
        <div className={`status-btn ${status.toLowerCase()}`}>{status}</div>
      </div>
    </div>
    <div className=' justify-between items-center p-4 hidden '>
      <p className='items'>{itemCount} items</p>
      <div className='flex justify-between items-center mt-2'>
        <div className={`status-btn ${status.toLowerCase()}`}>{status}</div>
      </div>
    </div>
  </div>
);

OrderCard.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  itemCount: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
};

export default OrderCard;
