// src/api/axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // URL de base pour les requêtes API
  withCredentials: true, // Pour que les cookies soient envoyés avec les requêtes (nécessaire pour Sanctum)
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Ajouter le token à chaque requête si disponible
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Gestion des erreurs, ex: redirection vers la page de login si 401
    if (error.response && error.response.status === 401) {
      // Gérer la déconnexion si nécessaire
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
