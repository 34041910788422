import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'
import ToolBar from '../components/ToolBar';
import ItemsSection from '../components/ItemsSection';
import { useEffect } from 'react';

function Purchase(){
    useEffect(()=>{
        document.title ='Cash Register'
    }, [])
    return (
    <div className='flex bg-[#E8E8E8] w-full'>
        <NavBar></NavBar>
        <div className='w-full'>
            <TopBar></TopBar>
            <ToolBar></ToolBar>
            <ItemsSection></ItemsSection>
        </div>
    </div>
)
}


export default Purchase;