import { PlusIcon, ArrowUpTrayIcon, ArrowDownTrayIcon, ListBulletIcon, Squares2X2Icon } from '@heroicons/react/24/solid';

const ActionBar = () => {
  return (
    <div className="flex items-center justify-between p-4 bg-white shadow">
      <div className="flex gap-3 items-center">
        <label htmlFor="">Items</label>
        <input
          type="text"
          placeholder="Search"
          className="input mr-4"
        />
      </div>
      <div className="flex items-center space-x-2">
        <button className="button-primary flex flex-row-reverse gap-3">
          <PlusIcon className="h-5 w-5 mr-2" />
          <span></span>
          <a href="/inventory/add-items">New</a>
        </button>
        <button className="button-secondary flex flex-row-reverse gap-3">
          <ArrowUpTrayIcon className="h-5 w-5 mr-2" />
          <span>Import</span>
        </button>
        <button className="button-secondary flex flex-row-reverse gap-3">
          <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
          <span>Export</span>
        </button>
        <div className="flex gap-1 bg-gray-100 p-1 rounded">
          <button className="button-secondary flex items-center justify-center p-2">
            <ListBulletIcon className="h-5 w-5" />
          </button>
          <button className="button-secondary flex items-center justify-center p-2">
            <Squares2X2Icon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};


export default ActionBar;
