

const ProductVariation = () => {
  return (
    <div className=" bg-white pb-3">
          <h2 className='text-base w-1/5 p-4 bg-[#F6F7F9] font-normal mb-4'>
        Product Variation
      </h2>
      <div className="mb-4 bg-red-100 p-3 rounded-lg mx-2 flex justify-between items-center">
        <div>
           <label className="mr-2">VA 01</label>
        <select className="mr-2 p-2 border bg-white rounded">
          <option value="red">Red</option>
          <option value="blue">Blue</option>
        </select>
        <select className="mr-2 p-2 bg-white border rounded">
          <option value="blue">Blue</option>
          <option value="red">Red</option>
        </select> 
        </div>
        
        <div>
         <button className="mr-2 p-2 bg-red-500 text-white rounded hover:bg-red-700">
          Delete
        </button>
        <button className="p-2 bg-gray-300 text-black rounded hover:bg-gray-500">
          Edit
        </button>   
        </div>
        
      </div>
      <div className="flex px-4">
        <div className="mr-4">
          <label className="block mb-1">SKU</label>
          <input
            type="text"
            className=" border w-72 py-2.5 p-2 rounded-lg"
            placeholder="Type here"
          />
        </div>
        <div>
          <label className="block mb-1">UPC</label>
          <input
            type="text"
            className="border-[#181D25] border w-72 py-2.5 p-2 rounded-lg"
            placeholder=""
          />
        </div>
      </div>
    </div>
  );
};

export default ProductVariation;
