import '../dist/output.css'
const TopSellingItems = () => {
  return (
    <div className='top-selling-container'>
      <div className='top-selling-header mb-5'>
        <div className='top-selling-title'>Top Selling Items</div>
        <select className='top-selling-period' name='' id=''>
          <option value=''>This Month</option>
        </select>{' '}
      </div>
      <div className='top-selling-items-container  p-5'>
        {[1, 2, 3, 4].map(item => (
          <div key={item} className='top-selling-item-card'>
            <div className='top-selling-item-image' />
            <div className='top-selling-item-name'>Item Name</div>
            <div className='flex items-center gap-2'>
              <div className='top-selling-item-count'>522</div>
              <div className='top-selling-item-unit'>Pcs</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TopSellingItems
