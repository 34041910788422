const ReportOverview = () => {
    return (
      <div className="report-container">
        <div className="report-title">Report overview</div>
        <div className='flex justify-between items-center'>
          <div className="report-amount">7,434.14 FCFA</div>
          <select className='report-time-period' name="" id="">
            <option value="">This Month</option>
        </select>
        </div>
        <div className="progress-bar-container">
          <div className="progress-bar bg-[#246BF6] w-[30%] rounded-l" />
          <div className="progress-bar bg-[#62AAFF] w-[20%]" />
          <div className="progress-bar bg-[#FF9900] w-[15%]" />
          <div className="progress-bar bg-[#FF5C5C] w-[10%]" />
          <div className="progress-bar bg-[#D9D9D9] w-[25%] rounded-r" />
        </div>
        <div className='flex justify-between mb-4 text-[#929FB1]'>
          <span>0%</span>
          <span>100%</span>
        </div>
        <div className="report-details">
          <div className="report-detail-item">
            <div className="report-detail-text">
              <div className="report-detail-icon bg-[#246BF6]" />
              <span className='text-[#606E80]'> Net volume from sales</span>
            </div>
            <div className="report-detail-value text-[#28a745]">
              +12% <span className='text-[#606E80]'>3,500.71 F</span>
            </div>
          </div>
          <div className="report-detail-item">
            <div className="report-detail-text">
              <div className="report-detail-icon bg-[#62AAFF]" />
              <span className='text-[#606E80]'> Net volume from purchases</span>
            </div>
            <div className="report-detail-value text-[#E02424]">
              -16.5% <span className='text-[#606E80]'>3,500.71 F</span>
            </div>
          </div>
          <div className="report-detail-item">
            <div className="report-detail-text">
              <div className="report-detail-icon bg-[#ff4500]" />
              <span className='text-[#606E80]'> Rejected orders</span>
            </div>
            <div className="report-detail-value text-[#00A44B]">
              +5% <span className='text-[#606E80]'>620.10 F</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default ReportOverview