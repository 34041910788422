import PropTypes from 'prop-types';
import UserDetail from './UserDetail';

const UserList = ({ onUserClick }) => {
  const users = [
    {
      name: 'Colombe A',
      status: 'inactive',
      email: 'vinovelve@gmail.com',
      role: 'Staff-Timesheet only',
      image: 'https://via.placeholder.com/150',
    },
    {
      name: 'Colombe A',
      status: 'invited',
      email: 'vinovelve@gmail.com',
      role: 'Admin',
      image: 'https://via.placeholder.com/150',
    },
  ];

  return (
    <div className="w-full max-w-full ">
      <div className="flex w-full p-4 bg-gray-100 border-b">
        <div className="flex items-center gap-4 w-1/2">
          <input type="checkbox" className="w-4 h-4 flex-shrink-0" />
          <div className="ml-4 text-lg font-semibold flex-grow">USER DETAIL</div>
        </div>
        <div className="flex items-center justify-between w-1/2">
          <div className="text-lg font-semibold">ROLES</div>
          <div className="w-12"></div>
        </div>
      </div>
      {users.map((user, index) => (
        <div key={index} onClick={() => onUserClick(user)} className="cursor-pointer">
          <UserDetail user={user} />
        </div>
      ))}
    </div>
  );
};

UserList.propTypes = {
  onUserClick: PropTypes.func.isRequired,
};

export default UserList;
