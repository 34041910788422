

const items = [
  { name: 'Item name', sku: 'Item 1 SKU', stock: 'In Stock (5000)', price: '19 000 000 F', category: 'Drink', tag: '#Tag-att', date: '12/12/2000', status: 'in-stock' },
  { name: 'Item name', sku: 'Item 1 SKU', stock: 'In Stock (5000)', price: '19 000 000 F', category: 'Drink', tag: '#Tag-att', date: '12/12/2000', status: 'in-stock' },
  { name: 'Item name', sku: 'Item 1 SKU', stock: 'In Restocking', price: '19 000 000 F', category: 'Drink', tag: '#Tag-att', date: '12/12/2000', status: 'restocking' },
  { name: 'Item name', sku: 'Item 1 SKU', stock: 'In Stock (5000)', price: '19 000 000 F', category: 'Drink', tag: '#Tag-att', date: '12/12/2000', status: 'in-stock' },
  { name: 'Item name - Draft', sku: 'Item 1 SKU', stock: 'Sold Out', price: '19 000 000 F', category: '----', tag: '-', date: '12/12/2000', status: 'sold-out' },
  { name: 'Item name', sku: 'Item 1 SKU', stock: 'In Stock (5000)', price: '19 000 000 F', category: 'Drink', tag: '#Tag-att', date: '12/12/2000', status: 'in-stock' },
];

const ItemTable = () => {
  return (
    <table className="item-table">
      <thead>
        <tr>
          <th><input type="checkbox" /></th>
          <th>NAME</th>
          <th>SKU</th>
          <th>Stock</th>
          <th>Price</th>
          <th>Category/Family</th>
          <th>Tag</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td><input type="checkbox" /></td>
            <td className="flex gap-3  items-center border-none">
               <img src="" alt="" className="bg-blue-500 w-20 h-14" />
                <span>{item.name}</span>
                </td>
            <td>{item.sku}</td>
            <td className="">
                <span className={`stock ${item.status}`}>{item.stock}</span>
                </td>
            <td>{item.price}</td>
            <td>{item.category}</td>
            <td>{item.tag}</td>
            <td className="flex flex-col gap-2">
                
                <span>Edit</span> 
                <span> {item.date}</span>
               </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ItemTable;
