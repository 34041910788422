import PropTypes from 'prop-types'

const BusinessCard = ({ icon, image, title, description, otherText }) => {
  return (
    <div className='bg-[#1235A0] text-white rounded-[20px] w-full sm-[40%] lg:w-[30%] p-4 flex flex-col items-center'>
      {/* Image Section */}
      <div className='w-full rounded-t-[20px] overflow-hidden flex items-center flex-col'>
        <img
          src={image}
          alt='business card'
          className='w-full md:w-[87%] lg:w-full h-[140px] object-cover rounded-t-md'
        />
      </div>
      <div className='border-x border-b px-6 pb-6 rounded-b-[22px]'>
        {/* Icon and Title Section */}
      <div className='flex gap-2 w-full items-start  relative h-[70px]'>
        <div className='bg-[#FF4E1F] rounded-xl p-2 flex items-center justify-center absolute -top-9 -left-12 '>
          <img src={icon} alt='business icon' className='' />
        </div>

        {/* Text Section */}
        <div className='flex flex-col justify-start absolute top-3 left-12'>
          <div className='text-lg font-bold'>{title}</div>
          <p className='text-sm leading-tight'>{description}</p>
        </div>
      </div>
      
      {/* Additional Information */}
      <div className='text-start text-sm pt-12'>{otherText}</div>
      </div>
      
    </div>
  )
}

BusinessCard.propTypes = {
  icon: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  otherText: PropTypes.string.isRequired,
}

export default BusinessCard
