import  { useState } from 'react';

const AttributeForm = () => {
  const [attributes, setAttributes] = useState([{ attribute: '', options: [] }]);

  const handleAttributeChange = (index, value) => {
    const newAttributes = [...attributes];
    newAttributes[index].attribute = value;
    setAttributes(newAttributes);
  };

  const handleOptionsChange = (index, value) => {
    const newAttributes = [...attributes];
    newAttributes[index].options = value.split(',');
    setAttributes(newAttributes);
  };

  const handleAddAttribute = () => {
    setAttributes([...attributes, { attribute: '', options: [] }]);
  };

  const handleRemoveAttribute = (index) => {
    const newAttributes = attributes.filter((_, i) => i !== index);
    setAttributes(newAttributes);
  };

  return (
    <div className=" flex flex-col gap-4 pb-4 bg-[#F6F7F9]">
      <label className="text-base w-1/5 p-4 bg-[#B0BECB40] font-normal mb-4">Create Attributes</label>
      {attributes.map((attr, index) => (
        <div key={index} className="mb-4 mx-auto w-[90%]">
          <div className="flex space-x-4">
            <div className="flex-grow">
              <label className="block text-gray-700">Attribute</label>
              <select
                value={attr.attribute}
                onChange={(e) => handleAttributeChange(index, e.target.value)}
                className="form-select mt-1 block w-72 py-2.5 px-3 rounded-lg"
              >
                <option value="">Select an attribute</option>
                <option value="Colors">Colors</option>
                <option value="Sizes">Sizes</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="flex-grow">
              <label className="block text-gray-700">Options</label>
              <input
                type="text"
                value={attr.options.join(',')}
                onChange={(e) => handleOptionsChange(index, e.target.value)}
                className="form-input mt-1 block  w-72 py-2.5 p-2 rounded-lg"
                placeholder="e.g., Red, Blue"
              />
            </div>
            <button
              onClick={() => handleRemoveAttribute(index)}
              className="mt-7 text-red-500 hover:text-red-700"
            >
              &#128465; {/* Unicode for trash icon */}
            </button>
          </div>
        </div>
      ))}
      <button
        onClick={handleAddAttribute}
        className="mt-2 text-blue-500 hover:text-blue-700"
      >
        + Add more Attributions
      </button>
    </div>
  );
};

export default AttributeForm;
