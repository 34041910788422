const ItemForm = () => {
  return (
    <div className='pb-4 bg-white  w-full'>
      <h2 className='text-base font-normal p-4 mb-4 bg-[#F6F7F9] w-1/5'>Item</h2>
      <form className=' flex justify-between items-start gap-4 px-8'>
        <div className="flex flex-col flex-1 gap-4">
          {' '}
          <div className="flex gap-4 justify-between">
            <label className='form-label' htmlFor='name'>
              Name <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              id='name'
              placeholder='Type here'
              className='input-field border h-10 pl-2 w-[300px] rounded-lg'
            />
          </div>
          <div className="flex gap-4 justify-between">
            <label className='form-label' htmlFor='description'>
              Description
            </label>
            <textarea
              id='description'
              placeholder=''
              className='text-area border border-[#E1E5EA] pl-2 w-[315px] h-20 rounded-lg'
            />
          </div>
        </div>

        <div className="flex flex-col flex-1 gap-4 items-start">
          <label className='form-label'>
            Type <span className='text-gray-500'>(i)</span>
          </label>
          <div className='space-y-2 flex flex-wrap'>
            <div className='flex items-center space-x-2 w-1/2'>
              <input
                type='radio'
                id='goods'
                name='type'
                className='form-radio'
              />
              <label htmlFor='goods' className='text-gray-700 '>
                Goods
              </label>
            </div>
            <div className='flex items-center space-x-2 w-1/2'>
              <input
                type='radio'
                id='service'
                name='type'
                className='form-radio'
              />
              <label htmlFor='service' className='text-gray-700'>
                Service
              </label>
            </div>
            <div className='flex items-center space-x-2 w-1/2'>
              <input
                type='radio'
                id='simple'
                name='productType'
                className='form-radio'
              />
              <label htmlFor='simple' className='text-gray-700'>
                Simple product
              </label>
            </div>
            <div className='flex items-center space-x-2 w-1/2'>
              <input
                type='radio'
                id='variable'
                name='productType'
                className='form-radio'
              />
              <label htmlFor='variable' className='text-gray-700'>
                Variable product
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ItemForm
