import { useState } from 'react';
import { FaTrash } from 'react-icons/fa'; // Assurez-vous d'avoir installé react-icons

const initialItems = Array(5).fill(null).map((_, index) => ({
  id: index + 1,
  description: 'Item ' + (index + 1),
  quantity: 0,
  price: 10000000,
}));

function ItemListComponent() {
  const [items, setItems] = useState(initialItems);

  const handleQuantityChange = (id, delta) => {
    const newItems = items.map(item => 
      item.id === id ? { ...item, quantity: item.quantity + delta } : item
    );
    setItems(newItems);
  };

  const handleDelete = (id) => {
    const newItems = items.filter(item => item.id !== id);
    setItems(newItems);
  };
  return (
    <div className="p-4">
      <div className="flex mb-4  font-bold">
        <div className="flex-1 text-left">Description</div>
        <div className="flex-1 text-center">QTE</div>
        <div className="flex-1 text-right">Total</div>
        <div className="flex-1 text-right"></div> {/* Colonne pour le bouton de suppression */}
      </div>
      {items.map((item) => (
        <div key={item.id} className="flex items-center mb-4 p-2  rounded">
          <div className="flex-1 text-left">{item.description}</div>
          <div className="flex-1 text-center">
            <div className="flex items-center border p-1 rounded space-x-2">
              <button 
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-1 px-2 rounded"
                onClick={() => handleQuantityChange(item.id, -1)}
              >
                -
              </button>
              <input 
                type="text" 
                value={item.quantity} 
                readOnly 
                className="w-12 text-center border rounded"
              />
              <button 
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-1 px-2 rounded"
                onClick={() => handleQuantityChange(item.id, 1)}
              >
                +
              </button>
            </div>
          </div>
          <div className="flex-1 text-right">{item.price * item.quantity}</div>
          <div className="flex-1 text-right">
            <button 
              className="text-red-500 hover:text-red-700 font-bold py-1 px-2 rounded"
              onClick={() => handleDelete(item.id)}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ItemListComponent;
