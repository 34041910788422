import { useEffect } from 'react'
import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'
import ItemBoxTable from '../components/ItemBoxTable'
import Filters from '../components/Filters'
import ActionBar from '../components/ActionBar'

function AddItemsBox(){
    
    useEffect(()=>{
        document.title ='Inventory > Items'
    }, [])
    return (
    <div className='flex bg-[#B2B0BF] w-full'>
        <NavBar></NavBar>
        <div className='w-full'>
            <TopBar></TopBar>
            <ActionBar></ActionBar>
            <Filters></Filters>
          <ItemBoxTable></ItemBoxTable>
        </div>
    </div>
)
}


export default AddItemsBox;