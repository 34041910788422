import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PropTypes from "prop-types";

import { AuthProvider } from "./contexts/AuthContext.js";
import { useAuth } from "./contexts/AuthContext.js";
import ProtectedRoute from "./routes/ProtectedRoute.js";
import Home from "./pages/Home";
// import About from './pages/About';
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import CashRegister from "./pages/Cash-Register";
import InventoryItem from "./pages/Inventory-Item";
import Sales from "./pages/Sales";
import Messages from "./pages/Messages";
import Documents from "./pages/Documents";
import AllStores from "./pages/All-Stores";
import Delivery from "./pages/Delivery";
import Finances from "./pages/Finances";
import Integrations from "./pages/Integrations";
import Inventory from "./pages/Inventory";
import OnlineStore from "./pages/Online-Store";
import Reports from "./pages/Reports";
import Purchase from "./pages/Purchase";
import AddInventoryItem from "./pages/AddInventoryItem";
import AddInventoryItemVariable from "./pages/AddInventoryItemVariable";
import Store1 from "./pages/Store1";
import AddItemsBox from "./pages/AddItemsBox";
import Settings from "./pages/Settings";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import LandingPage from "./pages/LandingPage.jsx";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/login"
            element={<PublicRoute component={Login} redirectTo="/dashboard" />}
          />
          <Route
            path="/signup"
            element={<Signup onSubmit={(data) => console.log(data)} />}
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/" element={<LandingPage />}></Route>

          <Route path="/home" element={<Home />}></Route>
          <Route path="/cash-register" element={<CashRegister />}></Route>
          <Route path="/inventory/items" element={<InventoryItem />}></Route>
          <Route path="/sales" element={<Messages />}></Route>
          <Route path="/messages" element={<Sales />}></Route>
          <Route path="/all-stores" element={<AllStores />}></Route>
          <Route path="/documents" element={<Documents />}></Route>
          <Route path="/delivery" element={<Delivery />}></Route>
          <Route path="/finances" element={<Finances />}></Route>
          <Route path="/inventory" element={<Inventory />}></Route>
          <Route path="/online-store" element={<OnlineStore />}></Route>
          <Route path="/reports" element={<Reports />}></Route>
          <Route path="/integrations" element={<Integrations />}></Route>
          <Route path="/purchase" element={<Purchase />}></Route>
          <Route
            path="/inventory/add-items"
            element={<AddInventoryItem />}
          ></Route>
          <Route
            path="/inventory/add-items-variable"
            element={<AddInventoryItemVariable />}
          ></Route>
          <Route path="/inventory/items-box" element={<AddItemsBox />}></Route>
          <Route path="/all-stores/store1" element={<Store1 />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/settings/users-roles/users" element={<Users />}></Route>
          <Route path="/settings/users-roles/roles" element={<Roles />}></Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

const PublicRoute = ({ component: Component, redirectTo }) => {
  const { user } = useAuth();
  if (user === undefined) {
    return <div>Loading...</div>;
  }
  return user ? <Navigate to={redirectTo} /> : <Component />;
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  redirectTo: PropTypes.string.isRequired,
};
export default App;
