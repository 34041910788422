
const SalesPurchaseForm = () => {
  return (
    <div className="pb-4  rounded w-full">
      <h2 className="text-base w-1/5 p-4 bg-[#B0BECB40] font-normal mb-4">Generale</h2>
      <div className="grid grid-cols-2 p-4 gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center mb-4">
            <input type="checkbox" id="salesInfo" className="form-checkbox" />
            <label htmlFor="salesInfo" className="ml-2 text-blue-600">Sales Information</label>
          </div>
          <div className="space-y-4">
            <div className="flex gap-5">
              <label className="block text-gray-700 font-medium mb-2 text-center w-[25%]" htmlFor="sellingPrice">
                Selling Price <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center  border border-[#B0BECB] rounded">
                <span className="bg-[#F6F7F9] p-2">FCFA</span>
                <input
                  type="text"
                  id="sellingPrice"
                  placeholder="Type here"
                  className="p-2  w-full"
                />
              </div>
            </div>
            <div className="flex gap-5">
              <label className="block text-gray-700 font-medium mb-2 text-center w-[25%]" htmlFor="resellerPrice">
                Reseller price
              </label>
              <div className="flex items-center border border-[#B0BECB] rounded">
                <span className="bg-[#F6F7F9] p-2">FCFA</span>
                <input
                  type="text"
                  id="resellerPrice"
                  placeholder="Type here"
                  className="p-2  w-full"
                />
              </div>
            </div>
            <div className="flex gap-5">
              <label className="block text-gray-700 font-medium mb-2 text-center w-[25%]" htmlFor="partnerPrice">
                Partner price
              </label>
              <div className="flex items-center border border-[#B0BECB] rounded">
                <span className="bg-[#F6F7F9] p-2 rounded-l">FCFA</span>
                <input
                  type="text"
                  id="partnerPrice"
                  placeholder="Type here"
                  className="p-2 w-full"
                />
              </div>
            </div>
            <div className="flex gap-5 ">
              <label className="block text-gray-700 font-medium mb-2 text-center w-[25%]" htmlFor="purchaseTax">
                Tax <span className="text-gray-500">(i)</span>
              </label>
              <select id="purchaseTax" className="p-2 border rounded w-[290px] bg-white">
                <option>Select a Tax</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center mb-4">
            <input type="checkbox" id="purchaseInfo" className="form-checkbox" />
            <label htmlFor="purchaseInfo" className="ml-2 text-blue-600">Purchase Information</label>
          </div>
          <div className="space-y-6">
            <div className="flex gap-5">
              <label className="block text-gray-700 text-center w-[25%] font-medium mb-2" htmlFor="costPrice">
                Cost Price <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center border border-[#B0BECB] rounded">
                <span className="bg-[#F6F7F9] p-2">FCFA</span>
                <input
                  type="text"
                  id="costPrice"
                  placeholder="Type here"
                  className="p-2 w-full"
                />
              </div>
            </div>
            <div className="flex gap-5">
              <label className="block text-gray-700 text-center w-[25%] font-medium mb-2" htmlFor="vendor">
                Vendor
              </label>
              <input
                type="text"
                id="vendor"
                placeholder="Type here"
                className="p-2 border rounded w-auto"
              />
            </div>
            <div className="flex gap-5 ">
              <label className="block text-gray-700 font-medium mb-2 text-center w-[25%]" htmlFor="purchaseTax">
                Tax <span className="text-gray-500">(i)</span>
              </label>
              <select id="purchaseTax" className="p-2 border rounded w-[290px] bg-white">
                <option>Select a Tax</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesPurchaseForm;
