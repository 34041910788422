import PropTypes from 'prop-types'

function SummaryComponent ({ total, discount, totalIncludingTax, cash }) {
  return (
    <div className='flex justify-between p-2 border-y-[#404B5A] gap-3'>
      <div className='flex flex-col items-start  bg-[#D9D9D9] w-[60%] p-2'>
        <div className='flex justify-evenly mb-2'>
          <span className='font-semibold'>Total</span>
          <span>{total}</span>
        </div>
        <div className='flex justify-between mb-2'>
          <span className='font-semibold'>Remise</span>
          <span>{discount}</span>
        </div>
        <div className='flex justify-between mb-2'>
          <span className='font-semibold'>TTC</span>
          <span>{totalIncludingTax}</span>
        </div>
        <div className='flex justify-between mb-2'>
          <span className='font-semibold'>CASH</span>
          <span>{cash}</span>
        </div>
      </div>
      <div className='w-[40%]'>
        <label htmlFor=''>M back</label>
        <input
          type='text'
          placeholder='M back'
          className='border rounded p-2 w-full mt-2 bg-[#D9D9D9]'
        />
      </div>
    </div>
  )
}

SummaryComponent.propTypes = {
  total: PropTypes.string.isRequired,
  discount: PropTypes.string.isRequired,
  totalIncludingTax: PropTypes.string.isRequired,
  cash: PropTypes.string.isRequired
}

export default SummaryComponent
