const ProductForm = () => {
  return (
    <div className='pb-4 bg-white  rounded w-full'>
      <h2 className='text-base w-1/5 p-4 bg-[#B0BECB40] font-normal mb-4'>
        Product Data
      </h2>
      <form className='w-full p-5 bg-white flex flex-col'>
        <div className='flex justify-between max-w-full gap-4'>
          <div className='flex flex-col flex-1 w-1/2'>
            <div className='mb-4 flex gap-2 items-center'>
              <label className='block text-gray-700'>SKU</label>
              <input
                type='text'
                className='form-input mt-1 block border-[#181D25] border w-72 py-2.5 p-2 rounded-lg'
                placeholder="Type Here"

              />
            </div>
            <div className='mb-4 flex gap-2 items-center'>
              <label className='block text-gray-700'>Unit</label>
              <select className='form-select mt-1 block w-72 py-2.5 p-2 rounded-lg'>
                <option>Select a unit</option>
                {/* Add options  */}
              </select>
            </div>
            <div className='mb-4 flex gap-2 items-center'>
              <label className='block text-gray-700'>UPC</label>
              <input
                type='text'
                className='form-input mt-1 block  border-[#181D25] border w-72 py-2.5 p-2 rounded-lg'
              />
            </div>
          </div>
          <div className='flex flex-col  flex-1 w-1/2'>
            <div className='mb-4 flex gap-2 items-center'>
              <label className='block w-[25%] text-gray-700'>Weight (kg)</label>
              <input
                type='text'
                className='form-input mt-1 block w-72 py-2.5 p-2 rounded-lg border-[#181D25] border'
              />
            </div>
            <div className='flex gap-3'>
              <h2 className=" w-[25%]">Dimensions</h2>
              <div className='mb-4 grid grid-cols-3 gap-4'>
                <div>
                  <label className='block  text-gray-700'></label>
                  <input
                    type='text'
                    className='form-input mt-1 block w-20 py-2.5 p-2 rounded-lg border-[#181D25] border'
                    placeholder='Length'
                  />
                </div>
                <div>
                  <label className='block text-gray-700'></label>
                  <input
                    type='text'
                    className='form-input mt-1 block w-20 py-2.5 p-2 rounded-lg border-[#181D25] border'
                    placeholder='Width'
                  />
                </div>
                <div>
                  <label className='block text-gray-700'></label>
                  <input
                    type='text'
                    className='form-input mt-1 block w-20 py-2.5 p-2 rounded-lg border-[#181D25] border'
                    placeholder='Height'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-4 flex justify-between items-start '>
          <label className='flex items-center'>
            <input
              type='checkbox'
              className='form-checkbox border-[#181D25] border'
            />
            <span className='ml-2'>Manage stock?</span>
          </label>
          {/* <div className='mb-4 grid grid-cols-2 gap-4'> */}
          <div>
            <label className='block text-gray-700'>Quantity in stock</label>
            <input
              type='text'
              className='form-input mt-1 block  border-[#B0BECB] border w-72 py-2.5 p-2 rounded-lg'
              placeholder="Type Here"

            />
          </div>
          <div>
            <label className='block text-gray-700'>Low stock threshold</label>
            <input
              type='text'
              className='form-input mt-1 block  border-[#B0BECB] border w-72 py-2.5 p-2 rounded-lg'
              placeholder="Type Here"
            />
          </div>
          {/* </div> */}{' '}
        </div>
      </form>
    </div>
  )
}

export default ProductForm
