import { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import TopBar from '../components/TopBar';
import ToolBar from '../components/ToolBar';
import ItemsSection from '../components/ItemsSection';
import PaymentComponent from '../components/PaymentComponent';
import InvoiceComponent from '../components/InvoiceComponent';
import ItemListComponent from '../components/ItemListComponent';
import SummaryComponent from '../components/SummaryComponent';
import FooterComponent from '../components/FooterComponent';
function CashRegister () {
  useEffect(() => {
    document.title = 'Cash Register';
  }, []);

  const [ticketId, setTicketId] = useState(2545);
  const [clientId, setClientId] = useState(2545);
  const [items, setItems] = useState([]); // Ajout d'un état pour stocker les éléments
  const [total, setTotal] = useState(0);

  const updateIds = () => {
    setTicketId(1234); 
    setClientId(5678); 
  };

  const calculateTotal = () => {
    // Exemple de calcul du total
    const totalAmount = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotal(totalAmount);
  };

  useEffect(() => {
    calculateTotal();
  }, [items]); // Recalcule le total lorsque les éléments changent

  return (
    <div className='flex bg-[#E8E8E8] max-w-full'>
      <NavBar />
      <div className='w-full'>
        <TopBar />
        <div className='flex justify-center max-w-full'>
          <div className='w-[70%]'>
            <ToolBar />
            <ItemsSection setItems={setItems} />
          </div>
          <div className='w-[30%] bg-white'>
            <PaymentComponent />
            <InvoiceComponent ticketId={ticketId} clientId={clientId} />
            <button className='hidden' onClick={updateIds}>Update IDs</button>
            <ItemListComponent items={items} setItems={setItems} /> 
            <SummaryComponent 
              total={total}
              discount="100 000 000" // Vous pouvez ajouter des valeurs dynamiques
              totalIncludingTax="100 000 000"
              cash="100 000 000"
            />
            <FooterComponent/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashRegister;
