import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Assurez-vous d'importer le CSS pour les styles de base

const PhoneNumberForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);

    if (value.length > 8) {
      setValidationMessage('Le numéro de téléphone est valide.');
    } else {
      setValidationMessage("Le numéro de téléphone n'est pas valide.");
    }
  };

  const removeFormControlClass = () => {
    const elements = document.querySelectorAll('.form-control');
    
    elements.forEach(element => {
      element.classList.remove('form-control');
    });
  };
  
  document.addEventListener('DOMContentLoaded', removeFormControlClass);

  return (
    <div className='mb-3'>

      <label htmlFor="phoneNumber">Phone</label>

      <PhoneInput
        country={'bj'}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        inputClass='border border-gray-300 bg-white pl-12  py-1 rounded react-tel-input-input'
        containerClass='w-full' 
      />
      <div
        id='phone-validation-message'
        style={{
          color:
            validationMessage === 'Le numéro de téléphone est valide.'
              ? 'green'
              : 'red'
        }}
      >
        {validationMessage}
      </div>
    </div>
  );
};

export default PhoneNumberForm;
