const ProductCategory = () => {
  return (
    <div className='mb-4 p-4 rounded-md'>
      <label className='block text-gray-700 mb-4'>Product Category</label>
      <div className='flex flex-col bg-white p-4'>
        <div className='mb-2'>
          <label className='flex items-center'>
            <input type='checkbox' className='form-checkbox' />
            <span className='ml-2'>Categorie 1</span>
          </label>
        </div>
        <div className='mb-2'>
          <label className='flex items-center'>
            <input type='checkbox' className='form-checkbox' />
            <span className='ml-2'>Categorie 1</span>
          </label>
        </div>
        <div className='mb-2'>
          <label className='flex items-center'>
            <input type='checkbox' className='form-checkbox' />
            <span className='ml-2'>Categorie 1</span>
          </label>
        </div>      
        <button className='mt-2 button button-gray w-1/2 p-4'>+ add category</button>

      </div>

    </div>
  )
}

export default ProductCategory
