import '@fortawesome/fontawesome-free/css/all.min.css';


const ToolBar = () => {
    return (
        <div className="toolbar">
          <button className="btn new-order">
            <i className="fas fa-plus"></i> New Order <i className="fas fa-angle-down"></i>
          </button>
          <button className="btn discount">
            <i className="fas fa-percentage"></i> Discount
          </button>
          <button className="btn shipping">
            <i className="fas fa-shipping-fast"></i> Shipping
          </button>
          <button className="btn actions">
            <i className="fas fa-cogs"></i> Actions <i className="fas fa-angle-down"></i>
          </button>
          <button className="btn cancel">
            <i className="fas fa-times"></i> Annuler
          </button>
          <button className="btn client">
            <i className="fas fa-user"></i> Client <i className="fas fa-angle-down"></i>
          </button>
          <button className="btn refunds">
            <i className="fas fa-undo"></i> Refunds
          </button>
          <button className="btn coupon">
            <i className="fas fa-tag"></i> Coupon
          </button>
          <button className="btn tpes">
            <i className="fas fa-credit-card"></i> TPEs
          </button>
          <button className="btn close">
            <i className="fas fa-times"></i> Fermer
          </button>
          <button className="btn sand">
            <i className="fas fa-play"></i> Sand <i className="fas fa-angle-down"></i>
          </button>
          <button className="btn add-notes">
            <i className="fas fa-sticky-note"></i> Add Notes
          </button>
          <button className="btn tickets">
            <i className="fas fa-ticket-alt"></i> Tickets
          </button>
          <button className="btn add-on">
            <i className="fas fa-plus"></i> Add on
          </button>
          <button className="btn tva-regs">
            <i className="fas fa-percent"></i> TVA Regs <i className="fas fa-angle-down"></i>
          </button>
          <button className="btn corner-icon">
            <i className="fas fa-caret-down"></i>
          </button>
        </div>
      );
    };

export default ToolBar;
