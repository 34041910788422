// import React from 'react';
import PropTypes from 'prop-types';

const SettingCard = ({ icon, title, links }) => {
  return (
    <div className="border border-[#C3CED7] rounded-lg  bg-[#F7F7FE] p-4 w-[17%] h-52">
      <div className="flex items-center mb-4">
        {icon && <div className="mr-2">{icon}</div>}
        <h3 className="font-bold">{title}</h3>
      </div>
      <ul className='flex flex-col gap-2'>
        {links.map((link, index) => (
          <li key={index} className=" hover:underline">
            {link}
          </li>
        ))}
      </ul>
    </div>
  );
};

SettingCard.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SettingCard;
