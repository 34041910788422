import { useEffect } from 'react'
import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'
import NewItemForm from '../components/NewItemForm'
import ItemForm from '../components/ItemForm'
import SalesPurchaseForm from '../components/SalesPurchaseForm'
import ProductFormRight from '../components/ProductFormRight'
import AttributeForm from '../components/AttributeForm'
import ProductVariation from '../components/ProductVariation'

function AddInventoryItemVariable () {
  useEffect(() => {
    document.title = 'Inventory > Add Items Variables'
  }, [])
  return (
    <div className='flex bg-[#F6F7F9] w-full'>
      <NavBar></NavBar>
      <div className='w-full'>
        <TopBar></TopBar>
        <NewItemForm></NewItemForm>
        <div className='flex'>
            <div className='w-2/3'>
                <ItemForm></ItemForm>
                <SalesPurchaseForm></SalesPurchaseForm>
                <AttributeForm></AttributeForm>
                <ProductVariation></ProductVariation>
            </div>
            <div className='w-1/3 bg-[#ffffff]'>
            <ProductFormRight></ProductFormRight>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddInventoryItemVariable
