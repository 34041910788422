import { useState } from 'react'
import Header from './ItemsBar'
import SearchBar from './SearchBar'
import GroupCard from './GroupCard'
import ItemCard from './ItemCard'
import OrderCard from './OrderCard'
import ButtonGroup from './ButtonGroup'

const ItemsSection = () => {
  const [activeTab, setActiveTab] = useState('All Items')
  // const searshBarSection = document.querySelector('.search-bar-section')
  if (activeTab == 'All Items') {
    // searshBarSection.classList.remove('hidden')
    // searshBarSection.classList.add('flex')
    // console.log(searshBarSection.classList)

    console.log('active')
  } else {
    console.log('not active')
  }
  const groups = [
    {
      name: 'Group Name',
      itemCount: 150,
      icon: 'fas fa-th-large',
      color: '#F68500'
    },
    {
      name: 'Group Name',
      itemCount: 150,
      icon: 'fas fa-box',
      color: '#3348FF'
    },
    {
      name: 'Group Name',
      itemCount: 150,
      icon: 'fas fa-comment',
      color: '#F32051'
    },
    {
      name: 'Group Name',
      itemCount: 150,
      icon: 'fas fa-gem',
      color: '#404B5A'
    },
    {
      name: 'Group Name',
      itemCount: 150,
      icon: 'fas fa-database',
      color: '#03A01C'
    }
  ]
  return (
    <div className='items-section flex flex-col gap-7 py-7 px-12'>
      <Header activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className='flex search-bar-section'>
        <SearchBar />
        <ButtonGroup></ButtonGroup>
      </div>
      <div className='content w-full'>
        <div className='group-container'>
          {groups.map((group, index) => (
            <GroupCard
              key={index}
              name={group.name}
              itemCount={group.itemCount}
              icon={group.icon}
              color={group.color}
            />
          ))}
        </div>
        <div className='item-container'>
          {[1, 2, 3, 4].map(item => (
            <ItemCard
              key={item}
              name='Item Name Ire I'
              category='Category, variante'
              price='10 000 000'
              stock='1000'
            />
          ))}
        </div>
        <div className='order-container py-4'>
          <OrderCard
            orderNumber='12548'
            status='Done'
            itemCount={100}
            time='2 min ago'
          />
          <OrderCard
            orderNumber='12548'
            status='Pending'
            itemCount={100}
            time='2 min ago'
          />
        </div>
      </div>
    </div>
  )
}

export default ItemsSection
