import { useEffect } from 'react'
import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'
import NewItemForm from '../components/NewItemForm'
import ItemForm from '../components/ItemForm'
import SalesPurchaseForm from '../components/SalesPurchaseForm'
import ProductForm from '../components/ProductForm'
import ProductFormRight from '../components/ProductFormRight'

function AddInventoryItem () {
  useEffect(() => {
    document.title = 'Inventory > Add Items'
  }, [])
  return (
    <div className='flex bg-[#F6F7F9] w-full'>
      <NavBar></NavBar>
      <div className='w-full'>
        <TopBar></TopBar>
        <NewItemForm></NewItemForm>
        <div className='flex'>
            <div className='w-2/3'>
                <ItemForm></ItemForm>
                <SalesPurchaseForm></SalesPurchaseForm>
                <ProductForm></ProductForm>
            </div>
            <div className='w-1/3'>
            <ProductFormRight></ProductFormRight>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddInventoryItem
