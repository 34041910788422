import PropTypes from 'prop-types';

const GroupCard = ({ name, itemCount, icon, color }) => (
  <div className='group-card'>
    <div className={`icon-container `}>
      {/* Utilisation de Font Awesome pour l'icône avec classe de taille */}
      <span className={`icon ${icon} text-[${color}] fa-2x`}></span>
    </div>
    <div>
      <h3 className={`text-lg font-semibold`}>{name}</h3>
      <p className='text-sm text-gray-600'>{itemCount} Items</p>
    </div>
  </div>
);

GroupCard.propTypes = {
  name: PropTypes.string.isRequired,
  itemCount: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default GroupCard;
