import Navbar from '../components/landing/Navbar'
import ceerix from '../assets/img/ceerix.svg'
import phones from '../assets/img/isolated_tablet_laptop_and_smartphone_composition 1.svg'
import check from '../assets/img/check_orange.svg'
import iconCaisse from '../assets/img/Frame 3467.svg'
import iconGestion from '../assets/img/Frame 3467(1).svg'
import iconStore from '../assets/img/Frame 3467(2).svg'
import iconSynchro from '../assets/img/Frame 3467(3).svg'
import iconDashboard from '../assets/img/Frame 3467(4).svg'
import iconNomDeDomaine from '../assets/img/Frame 3467(5).svg'
import quotes from '../assets/img/quotes.svg'
import star from '../assets/img/star.svg'
import restaurantIcon from '../assets/img/ion_restaurant.svg'
import restaurantImage from '../assets/img/Rectangle 1354.png'
import hotel from '../assets/img/hotel.svg'
import hotelImage from '../assets/img/bg-hotel.png'
import pharmacie from '../assets/img/pharmacie.svg'
import pharmacieImage from '../assets/img/bg-pharmacie.png'
import centreCommercial from '../assets/img/centre-commercial.svg'
import centreCommercialImage from '../assets/img/bg-centre.png'
import cabinet from '../assets/img/cabinet.svg'
import cabinetImage from '../assets/img/bg-cabinet.png'
import agence from '../assets/img/agence.svg'
import agenceImage from '../assets/img/bg-agence.png'
import caisse from '../assets/img/CAISSE ENREGISTREUSE TACTILE 2.png'
import mobile from '../assets/img/Free_Iphone_15_Mockup_5 1.png'
import ordinateur from '../assets/img/Free_MacBook_Pro_1 1.png'
import tablette from '../assets/img/Materiel-surface-2023-menu copie 1.png'
import flecheCaisse from '../assets/img/caisse.svg'
import flecheMobile from '../assets/img/mobile.svg'
import flecheOrdinateur from '../assets/img/ordinateur.svg'
import flecheTablette from '../assets/img/tablette.svg'
import client1 from '../assets/img/client1.png'
import client2 from '../assets/img/client2.png'
import client3 from '../assets/img/client3.png'
import client4 from '../assets/img/client4.png'
// import bgSection from '../assets/img/bg-section.png'
import Card from '../components/landing/Card'
import SubscriptionOffer from '../components/landing/SubscriptionOffer'
import ClientReview from '../components/landing/ClientReview'
import BusinessCard from '../components/landing/BusinessCard'

const LandingPage = () => {
  const features = [
    { text: 'Accès en ligne uniquement', included: true },
    { text: '1 Go de stockage', included: true },
    { text: 'Synchronisation des données', included: true },
    { text: 'Online Store inclus', included: false },
    { text: 'Nom de domaine personnalisé', included: false },
    { text: 'Mises à jour incluses', included: true }
  ]
  return (
    <div className='flex flex-col bg-[#16389F] w-full overflow-x-hidden '>
      <Navbar />
      <div className='flex flex-col bg-[#16389F] items-center  text-white'>
        <div className='flex flex-col items-center py-14 px-6 md:px-24 lg:px-52 gap-9'>
          <div className='text-xl md:text-2xl lg:text-3xl font-normal text-center font-inter'>
            Transformez la gestion de votre entreprise avec Ceerix
          </div>
          <div className='text-3xl md:text-4xl lg:text-5xl font-semibold text-center font-codec'>
            Le logiciel tout-en-un pour une gestion simplifiée de votre caisse
            et de vos stocks, avec une interface client intégrée.
          </div>
        </div>
        <div className='relative flex flex-col md:flex-row mt-16'>
          <div>
            <img src={ceerix} alt='Première Image' className='w-full' />
          </div>
          <div className='absolute -top-20 left-1/2 md:left-9 transform -translate-x-1/2 md:translate-x-0'>
            <img
              src={phones}
              alt='Deuxième Image'
              className='w-[80%] md:w-full'
            />
          </div>
        </div>
        <div className='ctas mt-16 lg:mt-72 flex flex-col md:flex-row gap-4 md:gap-8'>
          <button className='bg-[#FF4E1F] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
            <a href="#offres">    Voir nos offres       
</a>
          </button>
          <button className='border-[#FF4E1F] border-2 bg-white text-[#071E70] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
            Voir la demo
          </button>
        </div>
        <div className='flex flex-col items-center mt-10 md:mt-[59px] italic gap-5 '>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>Pour tout type d’entreprise</p>
          </span>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>
              Disponible sur caisse, ordinateur, tablette et mobile
            </p>
          </span>
        </div>
        <div className='flex flex-col items-center mt-20 md:mt-44 gap-6 md:gap-9 lg:w-[80%]'>
          <div className='text-3xl md:text-5xl lg:text-6xl font-semibold text-center font-codec'>
            Plus qu&apos;un simple logiciel de gestion
          </div>
          <div className='text-xl md:text-3xl lg:text-4xl font-normal text-center '>
            Des fonctionnalités puissantes pour gérer et développer votre
            entreprise efficacement.
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-7 flex-wrap items-center justify-center px-6 md:px-12 lg:px-36 my-20 lg:my-44'>
          <Card
            icon={iconCaisse}
            title='Caisse enregistreuse intuitive'
            description='Gérez vos ventes facilement grâce à une interface de caisse fluide et intuitive, conçue pour une utilisation rapide et efficace.'
          />
          <Card
            icon={iconGestion}
            title='Gestion intelligente des stocks'
            description='Surveillez vos stocks en temps réel, anticipez les ruptures, et optimisez votre inventaire grâce à des alertes automatiques et des rapports détaillés.'
          />
          <Card
            icon={iconStore}
            title='Online Store'
            description='Créez une boutique en ligne performante où vos clients peuvent passer des commandes directement depuis leur ordinateur ou smartphone.'
          />
          <Card
            icon={iconSynchro}
            title='Synchronisation multi-canaux'
            description='Travaillez sans interruption grâce à la synchronisation automatique de vos données entre les ventes en ligne et en magasin, même hors ligne.'
          />
          <Card
            icon={iconDashboard}
            title='Tableaux de bord personnalisables'
            description='Gardez un œil sur vos indicateurs clés de performance avec des tableaux de bord personnalisés qui affichent les données les plus importantes pour votre entreprise.'
          />
          <Card
            icon={iconNomDeDomaine}
            title='Nom de domaine personnalisé'
            description='Personnalisez votre boutique en ligne avec un nom de domaine unique, renforçant ainsi votre marque et votre présence en ligne.'
          />
        </div>
        <div className='ctas flex flex-col md:flex-row gap-4 md:gap-8'>
          <button className='bg-[#FF4E1F] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
                        <a href="#offres">  Voir nos offres</a>

          </button>
          <button className='border-[#FF4E1F] border-2 bg-white text-[#071E70] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
            Voir la demo
          </button>
        </div>
        <div className='flex flex-col items-center mt-10 md:mt-[59px] italic gap-5'>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>Pour tout type d’entreprise</p>
          </span>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>
              Disponible sur caisse, ordinateur, tablette et mobile
            </p>
          </span>
        </div>
        <div className='flex flex-col items-center mt-16 md:mt-44 gap-6 md:gap-9 w-[90%] md:w-[80%]'>
          <div className='text-3xl md:text-5xl lg:text-6xl text-center font-semibold font-codec'>
            Des offres flexibles pour toutes les entreprises
          </div>
          <div className='text-xl md:text-3xl lg:text-4xl font-normal text-center'>
            Choisissez l&apos;offre qui correspond le mieux à vos besoins.
          </div>
        </div>
        <div id='offres' className='flex flex-col md:flex-row flex-wrap lg:flex-nowrap justify-center mt-10 w-full gap-5'>
          {' '}
          <SubscriptionOffer
            type='BASIC'
            monthlyPrice={9}
            originalAnnualPrice={108}
            discountedAnnualPrice={90}
            features={features}
          />
          <SubscriptionOffer
            type='STANDARD'
            monthlyPrice={18}
            originalAnnualPrice={216}
            discountedAnnualPrice={180}
            features={features}
            isPopular='true'
          />
          <SubscriptionOffer
            type='PRO'
            monthlyPrice={27}
            originalAnnualPrice={324}
            discountedAnnualPrice={270}
            features={features}
          />
          <SubscriptionOffer
            type='PREMIUM'
            monthlyPrice={36}
            originalAnnualPrice={432}
            discountedAnnualPrice={360}
            features={features}
          />
        </div>
        <div className='ctas flex flex-col md:flex-row gap-4 mt-[39px] lg:mt-[59px] md:gap-8'>
          <button className='bg-[#FF4E1F] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
                        <a href="#offres">  Voir nos offres</a>

          </button>
          <button className='border-[#FF4E1F] border-2 bg-white text-[#071E70] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
            Voir la demo
          </button>
        </div>
        <div className='flex flex-col items-center mt-10 md:mt-[59px] italic gap-5'>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>Pour tout type d’entreprise</p>
          </span>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>
              Disponible sur caisse, ordinateur, tablette et mobile
            </p>
          </span>
        </div>
        <div className='flex flex-col mt-20 sm:mt-48 gap-7 items-center'>
          <img src={quotes} alt='' />
          <div className='flex gap-2 sm:gap-5'>
            <img src={star} alt='' />
            <img src={star} alt='' />
            <img src={star} alt='' />
            <img src={star} alt='' />
            <img src={star} alt='' />
          </div>
          <p className='text-xl sm:text-3xl'>Témoignages de nos clients</p>
          <h3 className='text-xl sm:text-3xl font-semibold'>
            Ce que nos clients disent de nous
          </h3>
        </div>{' '}
        <div className='flex flex-col lg:flex-row flex-wrap justify-center px-3 lg:px-0 items-center gap-8 mt-20'>
          <ClientReview
            image={client1}
            reviewText='Ceerix a changé notre façon de gérer notre magasin. Le logiciel est simple à utiliser et nous aide à rester organisés.'
            clientInfo="- Jean, Propriétaire d'un Supermarché"
          />
          <ClientReview
            image={client2}
            reviewText="La synchronisation multi-canaux est un game changer pour notre restaurant. Nous n'avons plus à nous soucier des décalages de données."
            clientInfo="- Marie, Gérante d'un Restaurant"
          />
          <ClientReview
            image={client3}
            reviewText='Le One Line Store a boosté nos ventes en ligne. Nos clients adorent la facilité de commande.'
            clientInfo="- Abdoulaye, Responsable d'une Pharmacie"
          />
          <ClientReview
            image={client4}
            reviewText='Grâce à Ceerix, nos clients peuvent maintenant passer des commandes en ligne, et nos ventes ont augmenté de 30%.'
            clientInfo="- Paula, Gérante d'un Restaurant"
          />
        </div>
        <div className='ctas flex flex-col md:flex-row gap-4 mt-[49px] md:gap-8'>
          <button className='bg-[#FF4E1F] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
                        <a href="#offres">  Voir nos offres</a>

          </button>
          <button className='border-[#FF4E1F] border-2 bg-white text-[#071E70] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
            Voir la demo
          </button>
        </div>
        <div className='flex flex-col items-center mt-10 md:mt-[59px] italic gap-5'>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>Pour tout type d’entreprise</p>
          </span>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>
              Disponible sur caisse, ordinateur, tablette et mobile
            </p>
          </span>
        </div>
        <div className='flex flex-col items-center text-center mt-44 w-[90%] sm:w-[80%] gap-9 mx-auto'>
          <div className='text-3xl sm:text-4xl lg:text-6xl font-semibold font-codec'>
            Un logiciel imaginé pour toutes les entreprises
          </div>
          <div className='text-xl sm:text-2xl lg:text-4xl font-normal font-inter'>
            Pour transformer la gestion des ventes et des stocks pour divers
            types d&apos;entreprises, tout en améliorant l&apos;expérience
            client.
          </div>
        </div>
        <div className='flex justify-center flex-col lg:flex-row items-center w-full flex-wrap px-4 gap-14 mt-[180px] mb-20'>
          <BusinessCard
            icon={restaurantIcon}
            image={restaurantImage}
            title='Restaurant'
            description='Classique, Fastfood, Brasserie, Bar, Café, Boulangerie, Pâtisserie, etc.'
            otherText="Personnalisez l'adresse web de votre boutique en ligne avec votre propre nom de domaine"
          />
          <BusinessCard
            icon={hotel}
            image={hotelImage}
            title='Hôtel'
            description='Môtel, Auberge, etc.'
            otherText="Personnalisez l'adresse web de votre boutique en ligne avec votre propre nom de domaine"
          />
          <BusinessCard
            icon={pharmacie}
            image={pharmacieImage}
            title='Pharmacie'
            description=''
            otherText="Personnalisez l'adresse web de votre boutique en ligne avec votre propre nom de domaine"
          />
          <BusinessCard
            icon={centreCommercial}
            image={centreCommercialImage}
            title='Centre commercial'
            description='Supermarché, boutique, prêt à porter, Electroménagers, Electronics, Meuble et décor, ect.'
            otherText="Personnalisez l'adresse web de votre boutique en ligne avec votre propre nom de domaine"
          />
          <BusinessCard
            icon={cabinet}
            image={cabinetImage}
            title='Cabinet comptable'
            description='Expertise comptable, Anal...'
            otherText="Personnalisez l'adresse web de votre boutique en ligne avec votre propre nom de domaine"
          />
          <BusinessCard
            icon={agence}
            image={agenceImage}
            title='Agence de communication'
            description=''
            otherText="Personnalisez l'adresse web de votre boutique en ligne avec votre propre nom de domaine"
          />
        </div>
        <div className='text-3xl md:text-5xl lg:text-6xl text-center font-semibold font-codec'>
          et bien d&apos;autres...{' '}
        </div>
        <div className='ctas flex flex-col md:flex-row gap-4 mt-[49px] md:gap-8'>
          <button className='bg-[#FF4E1F] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
                        <a href="#offres">  Voir nos offres</a>

          </button>
          <button className='border-[#FF4E1F] border-2 bg-white text-[#071E70] rounded-full py-3 px-12 md:px-20 font-medium text-xl md:text-2xl lg:text-3xl'>
            Voir la demo
          </button>
        </div>
        <div className='flex flex-col items-center mt-10 md:mt-[59px] italic gap-5'>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>Pour tout type d’entreprise</p>
          </span>
          <span className='flex gap-3'>
            <img src={check} alt='' className='w-6 md:w-8' />
            <p className='text-base md:text-lg'>
              Disponible sur caisse, ordinateur, tablette et mobile
            </p>
          </span>
        </div>
        <div className='flex flex-col items-center mt-16 md:mt-44 gap-6 md:gap-9 w-[90%] md:w-[80%]'>
          <div className='text-3xl md:text-5xl lg:text-6xl text-center font-semibold font-codec'>
            Une solution multiplateforme{' '}
          </div>
          <div className='text-xl md:text-3xl lg:text-4xl font-normal text-center'>
            Disponible sur caisse, ordinateur, tablette et mobile.{' '}
          </div>
        </div>
        <div className='bg-white bg-scroll hidden lg:flex flex-col w-full px-14 mt-60 justify-between relative h-[400px]'>
          {' '}
          {/* Ajout d'une hauteur explicite */}
          <div className='flex justify-between mr-36 absolute -top-32'>
            <div className='flex items-start'>
              <img src={caisse} alt='' />
              <img src={flecheCaisse} alt='' />
            </div>
            <div className='flex items-start'>
              <img src={ordinateur} alt='' />
              <img src={flecheOrdinateur} alt='' />
            </div>
          </div>
          <div className='flex justify-between ml-64 gap-28 absolute top-14'>
            <div className='flex items-end'>
              <img src={mobile} alt='' />
              <img src={flecheMobile} alt='' className='pb-12' />
            </div>
            <div className='flex items-end'>
              <img src={flecheTablette} alt='' className='pb-12' />
              <img src={tablette} alt='' />{' '}
            </div>
          </div>
        </div>
        <div className='bg-white lg:hidden bg-scroll flex flex-col items-center  w-full px-4 sm:px-8 md:px-14 mt-24 sm:mt-32 md:mt-60 justify-between relative h-[150px] sm:h-[200px]'>
          {/* Top Section */}
          <div className='flex sm:flex-row justify-between sm:mr-12 md:mr-36 absolute -top-10 sm:-top-24 md:-top-32 gap-12'>
            <div className='flex items-start space-x-2'>
              <img src={caisse} alt='' className='w-16 h-16 sm:w-24 sm:h-24' />
              <img
                src={flecheCaisse}
                alt=''
                className='w-16 h-16 sm:w-24 sm:h-24'
              />
            </div>
            <div className='flex items-start space-x-2'>
              <img
                src={ordinateur}
                alt=''
                className='w-16 h-16 sm:w-24 sm:h-24'
              />
              <img
                src={flecheOrdinateur}
                alt=''
                className='w-16 h-16 sm:w-24 sm:h-24'
              />
            </div>
          </div>

          {/* Bottom Section */}
          <div className='flex lg:hidden sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-8 md:space-x-28 absolute top-10 sm:top-24'>
            <div className='flex items-end space-x-2'>
              <img src={mobile} alt='' className='w-16 h-16 sm:w-24 sm:h-24' />
              <img
                src={flecheMobile}
                alt=''
                className='pb-6 sm:pb-12 w-16 h-16 sm:w-24 sm:h-24'
              />
            </div>
            <div className='flex items-end space-x-2'>
              <img
                src={flecheTablette}
                alt=''
                className='pb-6 sm:pb-12 w-16 h-16 sm:w-24 sm:h-24'
              />
              <img
                src={tablette}
                alt=''
                className='w-16 h-16 sm:w-24 sm:h-24'
              />
            </div>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url('/assets/img/bg-section.png')` }} 
          className='bg-transparent flex flex-col items-center'
        >
          <div className='flex flex-col items-center mt-16 md:mt-44 gap-6 md:gap-9 w-[90%] md:w-[80%]'>
            <div className='text-3xl md:text-5xl lg:text-6xl text-center font-semibold font-codec'>
              Voyez comment Ceerix peut transformer votre entreprise
            </div>
            <div className='text-xl md:text-3xl lg:text-4xl font-normal text-center'>
              Participez à notre démonstration en direct et découvrez les
              fonctionnalités de Ceerix en action
            </div>
          </div>
          <div className='ctas flex flex-col p-8 md:p-16 lg:p-28 gap-6 md:gap-8 lg:gap-8 w-[60%] lg:w-[55%]'>
            <button className='border-[#FF4E1F] border-2 bg-white text-[#071E70] rounded-full py-2 px-8 md:py-3 md:px-12 lg:py-3 lg:px-20 font-medium text-lg md:text-2xl lg:text-3xl'>
              Voir la demo
            </button>
            <button className='bg-[#FF4E1F] rounded-full py-2 px-8 md:py-3 md:px-12 lg:py-3 lg:px-20 font-medium text-lg md:text-2xl lg:text-3xl'>
              Accéder aux formations
            </button>
          </div>
        </div>
        <div id='contact' className='flex flex-col items-center w-full pb-24 px-4'>
          <div className='text-4xl md:text-5xl lg:text-6xl font-semibold text-center font-codec'>
            Envoyez nous un message !
          </div>
          <form
            action=''
            className='w-full flex flex-col items-center gap-6 md:gap-8 lg:gap-9 pt-12 md:pt-16'
          >
            <div className='w-full max-w-4xl flex flex-col md:flex-row gap-5 justify-center'>
              <input
                type='text'
                placeholder='Nom'
                className='flex-1 rounded-xl text-[#404B5A] h-16 md:h-20 p-4 md:p-6'
              />
              <input
                type='email'
                placeholder='Adresse email'
                className='flex-1 rounded-xl text-[#404B5A] h-16 md:h-20 p-4 md:p-6'
              />
            </div>
            <textarea
              name='message'
              placeholder='Message'
              className='w-full max-w-4xl h-64 md:h-80 rounded-xl text-[#404B5A] p-4 md:p-6'
            ></textarea>
            <button
              type='submit'
              className='bg-gradient-to-r from-[#2653D1] to-[#071E70] border-[#FF4E1F] mt-12 md:mt-16 lg:mt-28 py-3 md:py-4 px-8 md:px-12 lg:px-32 rounded-xl border-2 text-lg md:text-xl lg:text-2xl font-semibold'
            >
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
