import NavBar from '../components/NavBar'
import TopBar from '../components/TopBar'

function Dashboard(){
    return (
    <div className='flex bg-[#B2B0BF] w-full'>
        <NavBar></NavBar>
        <div className='w-full'>
            <TopBar></TopBar>
        </div>
    </div>
)
}


export default Dashboard;