import iconSend from '../assets/img/send2.png';
import iconPrint from '../assets/img/print.png';
import iconOrder from '../assets/img/cooking.png';
import iconConfirm from '../assets/img/check-boxed-white.png';


function FooterComponent () {
  return (
    <div className='flex justify-around flex-wrap space-y-6 items-center mt-4'>
      <button className='flex flex-col bg-[#EBF1FF] text-black font-bold p-5 rounded  items-center justify-center'>
        <img src={iconPrint} alt="" className='w-6' />
        <span>Print</span>
      </button>
      <button className='flex flex-col bg-[#3348FF] text-white font-bold p-5 rounded  items-center justify-center'>
        <img src={iconSend} alt="" className='w-6'/>

        <span>Send</span>
      </button>
      <button className='flex flex-col bg-[#F68500] text-white font-bold p-5 rounded  items-center justify-center'>
        <img src={iconOrder} alt="" className='w-6'/>

        <span>Order</span>
      </button>
      <button className='flex flex-col bg-[#03A01C] text-white font-bold p-5 rounded  items-center justify-center'>
        <img src={iconConfirm} alt="" className='w-6'/>

        <span>Confirm</span>
      </button>
    </div>
  )
}

export default FooterComponent
