import Card from '../components/Card'
import GreetSection from '../components/GreetSection'
import NavBar from '../components/NavBar'
import TabsBasics from '../components/TabsBasics'
import TopBar from '../components/TopBar'
import logoPackage from '../assets/img/package.png'
import logoDelivery from '../assets/img/truck-delivery-blue.png'
import logoTime from '../assets/img/time-clock.png'
import logoCheck from '../assets/img/check-boxed.png'
import ReportOverview from '../components/ReportOverview'
import ProductDetails from '../components/ProductDetails'
import TopSellingItems from '../components/TopSellingItems'
import SalesSection from '../components/SalesSection'
import TopSellingItemsTable from '../components/TopSellingItemsTable'

const Accueil = () => {
  const titleColor = '#181D25';
  return (
    <div className='flex bg-[#ffff] w-full'>
      <NavBar></NavBar>
      <div className='flex flex-col w-full gap-10'>
        <TopBar></TopBar>
        <div className='flex pl-5'>
          <GreetSection></GreetSection>
          <TabsBasics></TabsBasics>
        </div>
        <div className='home-main'>
          <div className="flex">
            <div className='cards-section'>
              <div className='card-section-item'>
                <Card
                  status='New'
                  title='TO BE PACKED'
                  bgColor='#CEFDDE'
                  titleColor={titleColor}
                  numberColor='#03A01C'
                  statusColor='#03A01C'
                  number='3'
                  icon={logoPackage}
                ></Card>
                <Card
                  status='Pending'
                  title='TO BE DELIVERED'
                  bgColor='#EBF1FF'
                  titleColor={titleColor}
                  numberColor='#3348FF'
                  statusColor='#3348FF'
                  number='3'
                  icon={logoDelivery}
                ></Card>
              </div>
              <div className='card-section-item'>
                <Card
                  status='Waiting'
                  title='TO BE SHIPPED'
                  bgColor='#FFEBC6'
                  titleColor={titleColor}
                  numberColor='#F68500'
                  statusColor='#F68500'
                  number='3'
                  icon={logoTime}
                ></Card>
                <Card
                  status='Resolved'
                  title='TO BE INVOICED'
                  bgColor='#00E0FF33'
                  titleColor={titleColor}
                  numberColor='#1BA4DF'
                  statusColor='#1BA4DF'
                  number='3'
                  icon={logoCheck}
                ></Card>
              </div>
            </div>
            <div className='w-1/2'>
            <ReportOverview className='w-full mr-9 '></ReportOverview>
            </div>
          </div>{' '}
          <div className='flex my-10'>
            <ProductDetails></ProductDetails>
            <TopSellingItems></TopSellingItems>
          </div>
          <div  className='flex'>
            <SalesSection></SalesSection>
            <TopSellingItemsTable></TopSellingItemsTable>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accueil
