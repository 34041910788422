const NewItemForm = () => {
  return (
    <div className='p-4 bg-white rounded justify-between items-center shadow-md flex'>
      <span className='p-2 w-1/2 rounded  mb-4'>New Item</span>
      <div className='flex justify-end space-x-4'>
        <button className='button button-gray'>Save as Draft</button>
        <button className='button button-blue text-white flex justify-center items-center gap-2'>
          {' '}
          <span>+</span>
          <span>Save</span>
          
        </button>
        <button className='button button-gray flex justify-center items-center gap-2 text-lg'>
          {' '}
          <span className="text-gray-400">X</span>
          <span>Cancel</span>
          
        </button>
      </div>       
       <button className='text-red-500 border px-4 py-2'>X</button>

    </div>
  )
}

export default NewItemForm
