import { useState } from 'react'
import PropTypes from 'prop-types'

const InviteUserForm = ({ onClose }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    console.log('Invitation envoyée à:', email, 'pour le rôle:', role)
    onClose()
  }

  return (
    <div className='fixed inset-0  top-0 bg-gray-600 bg-opacity-50 flex justify-center items-center'>
      <div className=' w-1/3 bg-white p-6 rounded-md shadow-md '>
        <h2 className='text-lg font-semibold mb-4'>Inviter un utilisateur</h2>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='email'
            >
              Name
            </label>
            <input
              type='text'
              id='name'
              value={name}
              onChange={e => setName(e.target.value)}
              required
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='email'
            >
              Mail Address{' '}
            </label>
            <input
              type='email'
              id='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='role'
            >
              Rôle
            </label>
            <input
              type='text'
              id='role'
              value={role}
              onChange={e => setRole(e.target.value)}
              required
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>
          <div className='flex gap-4 mt-20 pt-5 border-t'>
            <button
              type='submit'
              className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none'
            >
              Send
            </button>{' '}
            <button
              type='button'
              onClick={onClose}
              className=' px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none'
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

InviteUserForm.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default InviteUserForm
