import PropTypes from 'prop-types';
import styled from 'styled-components';

// Style du composant
const CardContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 8px;
`;

const Status = styled.div`
  color: ${(props) => props.statusColor};
  font-size: 12px;
`;

const Title = styled.div`
  font-size: 16px;
 font-weight: semibold;
  margin: 8px 0;
  color: ${(props) => props.titleColor};
`;

const Number = styled.div`
  font-size: 48px;
  color: ${(props) => props.numberColor};
`;

// Composant Card
const Card = ({ status, title, number, icon, bgColor, statusColor, titleColor, numberColor }) => {
  return (
    <CardContainer bgColor={bgColor} className='flex flex-col p-5 w-72'>
      <div>
        <Status statusColor={statusColor}>{status}</Status>
        <Title titleColor={titleColor}>{title}</Title>
      </div>
      <div className='flex items-center justify-between'>
        <Number numberColor={numberColor}>{number}</Number>
        <img src={icon} className='w-11 h-11' alt='' />
      </div>
    </CardContainer>
  );
};

Card.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.string.isRequired,
  bgColor: PropTypes.string, 
  statusColor: PropTypes.string, 
  titleColor: PropTypes.string, 
  numberColor: PropTypes.string, 
};

export default Card;
