import { useState } from 'react';
import SettingsMenu from '../components/SettingsMenu';
import SettingsHeader from '../components/SettingsHeader';
import TopBar from '../components/TopBar';
import UserList from '../components/UserList'; // Verify the correct path
// import UserDetail from '../components/UserDetail'; // Verify the correct path
import EditUserForm from '../components/EditUserForm'; // Verify the correct path
import CustomUserDetail from '../components/CustomUserDetail'; 
import InviteUserForm from '../components/InviteUserForm'; // Importez le composant

const Users = () => {
  // State and handlers remain the same
  const [showSettingsMenu, setShowSettingsMenu] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [showInviteForm, setShowInviteForm] = useState(false);
  const handleSettingsClick = () => {
    setShowSettingsMenu(!showSettingsMenu);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const closeUserDetail = () => {
    setSelectedUser(null);
  };

  const handleEditClick = (user) => {
    setEditingUser(user);
  };

  const closeEdit = () => {
    setEditingUser(null);
  };

  const toggleInviteForm = () => {
    setShowInviteForm(!showInviteForm);
  };

  const closeInviteForm = () => {
    setShowInviteForm(false);
  };


  return (
    <div className="flex flex-col gap-4 bg-white w-full">
      <TopBar />
      <SettingsHeader onSettingsClick={handleSettingsClick} showSettingsMenu={showSettingsMenu} />
      {showSettingsMenu ? (
        <div className="flex">
          <SettingsMenu />
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center p-4 bg-white shadow-md pr-32">
              <div className="flex items-center">
                <span className="text-lg font-semibold">All Users</span>
                <svg
                  className='w-4 h-4 ml-2 text-gray-500'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M19 9l-7 7-7-7'
                  ></path>
                </svg>
              </div>
              <div className="flex items-center gap-2">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
                  onClick={toggleInviteForm} // Affiche le formulaire d'invitation lors du clic
                >
                  Inviter un utilisateur
                </button>
                <button id='details-btn' className='p-2 rounded border focus:outline-none hover:bg-gray-400 bg-gradient-to-r from-[#FFFFFF14] to-[#6666660C] h-10 w-10'>
                  <svg
                    className='w-6 h-6 text-gray-600'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M12 8c-1.1 0-1.99-.89-1.99-2S10.9 4 12 4s2 .89 2 2-.9 2-2 2zm0 4c-1.1 0-1.99-.89-1.99-2S10.9 8 12 8s2 .89 2 2-.9 2-2 2zm0 4c-1.1 0-1.99-.89-1.99-2S10.9 12 12 12s2 .89 2 2-.9 2-2 2z' />
                  </svg>
                </button>
              </div>
            </div>
            <UserList onUserClick={handleUserClick} />
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap gap-5 py-4 justify-center h-screen">
          <UserList onUserClick={handleUserClick} />
        </div>
      )}
      {selectedUser && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-md w-1/2 relative">
            
            {/* Render CustomUserDetail instead of UserDetail */}
            <CustomUserDetail user={selectedUser} onClose={closeUserDetail} onEdit={handleEditClick} />
          </div>
        </div>
      )}
      {editingUser && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-md w-1/2 relative">
            <button onClick={closeEdit} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              &times;
            </button>
            <EditUserForm user={editingUser} />
          </div>
        </div>
      )}
      {showInviteForm && (
        <InviteUserForm onClose={closeInviteForm} /> 
      )}
    </div>
  );
};

export default Users;

