// Settings.js
import  { useState } from 'react';
import PropTypes from 'prop-types';
import iconOrganisation from '../assets/img/icon-organisation.png';
import iconPreferences from '../assets/img/icon-preference.png';
import iconDelivery from '../assets/img/icon-delivery.png';
import iconPurchases from '../assets/img/icon-purchases.png';
import iconUsersRoles from '../assets/img/icon-users-roles.png';
import iconSales from '../assets/img/icon-sales.png';
import iconOnlinePayement from '../assets/img/icon-online-payement.png';
import inconIntegration from '../assets/img/icon-integration.png';
import iconTaxes from '../assets/img/icon-taxes.png';
import iconItems from '../assets/img/icon-items.png';
import SettingCard from '../components/SettingsCard';
import TopBar from '../components/TopBar';
import SettingsHeader from '../components/SettingsHeader';
import SettingsMenu from '../components/SettingsMenu';

// Sample icons for demonstration
const SampleIcon = ({ src }) => (
  <img src={src} alt='' className='w-5 h-5' />
);

SampleIcon.propTypes = {
  src: PropTypes.string.isRequired,
};

const Settings = () => {
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  const handleSettingsClick = () => {
    setShowSettingsMenu(!showSettingsMenu);
  };

  const cards = [
    {
      icon: <SampleIcon src={iconOrganisation} />,
      title: 'Organization',
      links: ['Profile', 'Warehouses'],
    },
    { icon: <SampleIcon src={iconTaxes} />, title: 'Taxes & Compliance', links: ['Profile'] },
    { icon: <SampleIcon src={iconPreferences} />, title: 'Preferences', links: ['Profile'] },
    { icon: <SampleIcon src={iconItems} />, title: 'Items', links: ['Profile'] },
    { icon: <SampleIcon src={iconSales} />, title: 'Sales', links: ['Profile'] },
    { icon: <SampleIcon src={iconPurchases} />, title: 'Purchases', links: ['Profile'] },
    { icon: <SampleIcon src={iconUsersRoles} />, title: 'Users & Roles', links: ['Users', 'Roles'] },
    { icon: <SampleIcon src={iconOnlinePayement} />, title: 'Online Payments', links: ['Profile'] },
    { icon: <SampleIcon src={inconIntegration} />, title: 'Integrations', links: ['Profile'] },
    { icon: <SampleIcon src={iconDelivery} />, title: 'Delivery Method', links: ['Profile'] },
  ];

  return (
    <div className='flex flex-col gap-4 bg-white w-full'>
      <TopBar />
      <SettingsHeader onSettingsClick={handleSettingsClick} showSettingsMenu={showSettingsMenu} />
      {showSettingsMenu ? (
        <SettingsMenu />
      ) : (
        <div className='flex flex-wrap gap-5 py-4 justify-center h-screen'>
          {cards.map((card, index) => (
            <SettingCard key={index} icon={card.icon} title={card.title} links={card.links} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Settings;
