// PaymentComponent.jsx

function PaymentComponent () {
  return (
    <div className='p-4'>
      <h2 className='text-lg font-semibold mb-4'>Payement</h2>
      <div className='flex w- items-center mb-4'>
        <div className='relative inline-block w-[25%]'>
          <select className='block appearance-none bg-white border border-[#E1E5EA] hover:border-gray-500 rounded-l px-4 py-2'>
            <option value='CASH'>CASH</option>
            <option value='CREDIT'>CREDIT</option>
          </select>
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
            <svg
              className='fill-current h-4 w-4'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
            >
              <path d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' />
            </svg>
          </div>
        </div>
        <div className='flex items-center  w-[75%] border border-y-[#E1E5EA] border-r-[#E1E5EA] pr-4 rounded-r'>
          <input type='text' className=' w-[75%]  px-4 py-2' placeholder='Amount' />
          <span className=' w-[25%] font-semibold'>FCFA</span>
        </div>
      </div>
      <div className='flex items-center space-x-4 mb-4'>
        <button className='bg-blue-500 text-white button rounded-lg'>
          Unit
        </button>
        <button className='button button-gray rounded-lg'>Gros</button>
        <button className='button button-gray rounded-lg'>Part</button>
      </div>
      <div className='flex items-center gap-0'>
        <button className='button button-gray rounded-l-lg'>Commander</button>
        <button className='bg-green-500 text-white button rounded-r-lg'>
          Encaisser
        </button>
      </div>
    </div>
  )
}

export default PaymentComponent
