import PropTypes from 'prop-types'
import greenCheck from '../../assets/img/check-green.svg'
import redCroix from '../../assets/img/red-croix.svg'

const SubscriptionOffer = ({
  type,
  monthlyPrice,
  originalAnnualPrice,
  discountedAnnualPrice,
  features,
  isPopular
}) => {
  return (
    <>
      <div className='rounded-[20px] bg-[#1235A0] text-white py-6 px-4 flex flex-col items-center w-full sm:w-[75%] md:w-[50%] lg:w-[30%]'>
        {/* Type d'abonnement */}
        <div
          className={
            isPopular ? 'block bg-[#FF4E1F] text-lg sm:text-xl text-center w-full p-4 rounded-t-[20px] sm:p-6 font-extrabold' : 'hidden'
          }
        >
          Le plus populaire
        </div>

        <div className={`border bg-[#1235A0] text-white flex flex-col items-center gap-5 w-full   rounded-[20px] ${isPopular ? 'mt-0 border-t-none rounded-t-none' : 'lg:mt-[78px] '}`}>          {/* si c'est populaire, pt-0 sinon pt-10 isPopular */}
          <div className='text-[22px] sm:text-[25px] md:text-[27px] font-bold pb-4 border-b w-[90%] text-center font-codec'>
            {type}
          </div>

          {/* Prix par mois */}
          <div className='text-2xl sm:text-3xl font-semibold'>
            {monthlyPrice} USD / mois
          </div>

          {/* Prix annuel avec réduction */}
          <div className='text-xs sm:text-sm text-white py-2 px-4 sm:px-5 rounded-[32px] bg-gradient-to-r from-[#FF7F00] to-[#FF4E1F] font-semibold'>
            Économisez{' '}
            {Math.round(
              ((originalAnnualPrice - discountedAnnualPrice) /
                originalAnnualPrice) *
                100
            )}
            %
          </div>
          <div className='flex flex-col sm:flex-row justify-between text-center items-center gap-5'>
            <div className='line-through mb-1 opacity-[50%] text-xs'>
              {originalAnnualPrice} USD / an
            </div>
            <div className='text-base sm:text-lg font-semibold'>
              {discountedAnnualPrice} USD / an
            </div>
          </div>

          {/* Bouton */}
          <button className='bg-white text-[#071E70] font-semibold text-base sm:text-lg rounded-[10px] border-2 border-[#FF4E1F] w-full lg:w-[80%] py-2 px-8'>
            Choisir
          </button>

          {/* Liste des fonctionnalités */}
          <ul className='mt-6 text-sm sm:text-lg font-normal w-[80%]'>
            {features.map((feature, index) => (
              <li
                key={index}
                className={`flex items-center justify-start mb-2 `}
              >
                <span className='mr-2'>
                  <img src={feature.included ? greenCheck : redCroix} alt='' />
                </span>
                {feature.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

SubscriptionOffer.propTypes = {
  type: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.number.isRequired,
  originalAnnualPrice: PropTypes.number.isRequired,
  discountedAnnualPrice: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      included: PropTypes.bool.isRequired
    })
  ).isRequired,
  isPopular: PropTypes.bool.isRequired
}

export default SubscriptionOffer
