import { useState } from 'react';
import SettingsMenu from '../components/SettingsMenu';
import SettingsHeader from '../components/SettingsHeader';
import TopBar from '../components/TopBar';
import RoleList from '../components/RoleList';

const Roles = () => {
  const [showSettingsMenu, setShowSettingsMenu] = useState(true);
  
  const handleSettingsClick = () => {
    setShowSettingsMenu(!showSettingsMenu);
  };


  return (
    <div className="flex flex-col gap-4 bg-white w-full">
      <TopBar />
      <SettingsHeader onSettingsClick={handleSettingsClick} showSettingsMenu={showSettingsMenu} />
      {showSettingsMenu ? (
        <div className="flex">
          <SettingsMenu />
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center p-4 bg-white shadow-md pr-32">
              <div className="flex items-center">
                <span className="text-lg font-semibold">Roles</span>
                
              </div>
            
            </div>
            <RoleList/>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap gap-5 py-4 justify-center h-screen">
            <RoleList/>

        </div>
      )}
     
    </div>
  );
};

export default Roles;

